import _get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { AnswerAction } from './Labelling/LabellingQuestionType';
import { AnsweredItem, MatchingOrLabellingQuestionTypeProps, PossibleAnswer } from '../../typings/DraggableQuestionTypes';

export interface StateManger {
  answeredItems: AnsweredItem[];
  getAnswers: () => Ctek.Answer[];
  getContent: () => any[];
  getPossibleAnswers: () => PossibleAnswer[];
  handleAnswerSubmitted: (evt: any, answerAction: AnswerAction) => void;
  handleDrop: (index: number, item: { id: string }) => void;
  isAllItemsLabelled: () => boolean;
  isDropped: (id: string) => boolean;
  isNoItemLabelled: () => boolean;
  nextQuestionLoading: boolean;
  removeIdFromAnsweredItems: (id: string) => void;
  resetAnswers: () => void;
}

interface StateMangerProps extends MatchingOrLabellingQuestionTypeProps {
  setSelectedItem?: (id: string | null) => void;
  injectedAnswers?: AnsweredItem[];
}

const useMatchingAndLabellingStateManager = (props: StateMangerProps): StateManger => {
  const [answeredItems, setAnsweredItems] = useState<AnsweredItem[]>(props.injectedAnswers ?? []);
  const { handleQuestionAnswered, question, answerType, setSelectedItem } = props;

  // AS-1104 hack to be able to inject answer to be pre-populated
  // used when revisiting question in assessment player
  useEffect(() => {
    if (!props.injectedAnswers) {
      setAnsweredItems([]);
    }
  }, [props.injectedAnswers]);

  const getAnswers = useCallback(() => {
    const answers = _get(props, ['question', 'data', 'answerGroups', '0', 'answers'], null);
    if (!answers) {
      throw Error('Incorrect data provided to question. No answer groups available');
    }
    return answers;
  }, [props]);

  const getContent = () => {
    const content = _get(props, ['question', 'data', 'content'], null);
    if (!content) {
      throw Error('Incorrect data provided to question. No question content available');
    }
    return content;
  };

  const getPossibleAnswers = () => {
    const possibleAnswers = _get(props, ['question', 'data', 'possibleAnswers'], null);
    if (!possibleAnswers) {
      throw Error('Incorrect data provided to question. No possible answers available');
    }
    return possibleAnswers;
  };

  // Remove an AnswerGroup or Possible answer from answered items when possible answer dragged away.
  const removeId = useCallback((id: string, currentAnsweredItems: AnsweredItem[]) => {
    const newAnsweredItems = [...currentAnsweredItems];
    return newAnsweredItems.filter(item => item.answerGroupAnswerId !== id && item.possibleAnswerId !== id);
  }, []);

  // Handles drop of label into item box.
  const handleDrop = useCallback(
    (index: number, item: { id: string }) => {
      setAnsweredItems(prevAnsweredItems => {
        const { id } = item;
        // Remove from existing Label Pair if it has previously been dropped.
        const removedDroppedId = removeId(id, prevAnsweredItems);
        const removedOtherIds = removeId(getAnswers()[index].id, removedDroppedId);
        removedOtherIds.push({ answerGroupAnswerId: getAnswers()[index].id, possibleAnswerId: id });
        return [...removedOtherIds];
      });

      // If using the click drag and drop manager, set this to null when something is dropped.
      if (setSelectedItem) {
        setSelectedItem(null);
      }
    },
    [getAnswers, setSelectedItem, removeId]
  );

  // Returns true if a label is assigned to and itembox.
  const isDropped = (id: string) => {
    return answeredItems.find(item => {
      return item.possibleAnswerId === id || item.possibleAnswerId === id;
    })
      ? true
      : false;
  };

  const removeIdFromAnsweredItems = (id: string) => {
    const newAnsweredItems = removeId(id, answeredItems);
    setAnsweredItems(newAnsweredItems);

    // If using the click drag and drop manager, set this to null when something is dropped.
    if (setSelectedItem) {
      setSelectedItem(null);
    }
  };

  const resetAnswers = () => {
    setAnsweredItems([]);
  };

  const isAllItemsLabelled = useCallback(() => {
    return getAnswers().length === answeredItems.length;
  }, [getAnswers, answeredItems]);

  const isNoItemLabelled = () => {
    return answeredItems.length === 0;
  };

  const [nextQuestionLoading, setQuestionLoading] = useState(false);

  const handleAnswerSubmitted = useCallback(
    (evt: any, answerAction: AnswerAction) => {
      if (nextQuestionLoading || (AnswerAction.Answer === answerAction && !isAllItemsLabelled())) {
        return;
      }

      evt.preventDefault();
      setQuestionLoading(true);

      const answer = {
        answerAction,
        answerType,
        answers: answerAction === AnswerAction.Skip ? [] : answeredItems,
        assessmentId: question.assessmentId,
        questionGroupId: question.questionGroupId,
        questionId: question.data._id,
      };
      handleQuestionAnswered(answer, question.isClosedOnSubmit);
    },
    [answerType, answeredItems, question, handleQuestionAnswered, isAllItemsLabelled, nextQuestionLoading]
  );

  // Listen for the enter key being pressed. If it is pressed submit the question.
  // We only do this when the activeElement is the body of the DOM as if the user is focussed on something else,
  // enter should submit on that. When a user drags and drops an element, the focus moves to the body.
  useEffect(() => {
    const onEnter = (evt: KeyboardEvent) => {
      if (isAllItemsLabelled() && evt.key === 'Enter' && document?.activeElement?.tagName.toLowerCase() === 'body') {
        evt.preventDefault();
        handleAnswerSubmitted(evt, AnswerAction.Answer);
      }
    };
    window.addEventListener('keyup', onEnter);

    return () => window.removeEventListener('keyup', onEnter);
  }, [isAllItemsLabelled, handleAnswerSubmitted]);

  return {
    answeredItems,
    getAnswers,
    getContent,
    getPossibleAnswers,
    handleAnswerSubmitted,
    handleDrop,
    isAllItemsLabelled,
    isDropped,
    isNoItemLabelled,
    nextQuestionLoading,
    removeIdFromAnsweredItems,
    resetAnswers,
  };
};

export default useMatchingAndLabellingStateManager;
