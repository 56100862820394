/**
 * Event names are defined by Data team in the Mixpanel: Taxonomy
 *
 * https://docs.google.com/spreadsheets/d/1wTJ1Y6jNO-xLM1zSgZCpaEY5IA95ZUx4Ih_5FT4TRmQ/
 */

export enum MixpanelEventTypes {
  PageViewed = 'Page Viewed',
  LoginSessionStarted = 'Login Session Started',
  EmailAdded = 'Email Added',
  EmailRemoved = 'Email Removed',
  AvatarChanged = 'Avatar Changed',
  FeedbackSubmitted = 'Feedback Submitted',
  RevealBalanceChecked = 'Reveal Balance Checked',
  ImageNotLoaded = 'Image Not Loaded',
  AnnouncementDismissed = 'Announcement Dismissed',
  // Assignment app
  AssignmentAssigned = 'Assignment Assigned',
  AssignmentCreated = 'Assignment Created',
  AssignmentBoardFilterApplied = 'Assignment Board Filter Applied',
  AssignmentDeleted = 'Assignment Deleted',
  AssignmentGraded = 'Assignment Graded',
  AssignmentGoalGraded = 'Assignment Goal Graded',
  AssignmentPublished = 'Assignment Published',
  GoalAdded = 'Goal Added',
  GoalDeleted = 'Goal Deleted',
  NuggetSearched = 'Assignable Nuggets Searched',
  NuggetsSelected = 'Assignable Nuggets Selected',
  SelfEstimated = 'Self Estimated',
  TaskCreated = 'Assignment Task Created',
  //  CMS
  QuestionCloned = 'CMS Question Cloned',
  QuestionCreated = 'CMS Question Created',
  QuestionRemoved = 'CMS Question Removed',
  QuestionGroupSaved = 'CMS Question Group Saved',
  // QLA
  QLAOpened = 'QLA Window Opened',
  QLAClosed = 'QLA Window Closed',
  QLANavigated = 'QLA Window Navigated',
  // B2C App
  B2CGuardianCreated = 'B2C Guardian Created',
  B2CLearnerCreated = 'B2C Learner Created',
  B2CSubscriptionChosen = 'B2C Subscription Chosen',
  B2CLearnerCoursesSelected = 'B2C Learner Courses Selected',
  B2CLearnerPasswordChanged = 'B2C Learner Password Changed',
  B2CPaymentDetailsEntered = 'B2C Payment Details Entered',
  B2CLearnerRemoved = 'B2C Learner Removed',
  B2CLearnerRestored = 'B2C Learner Restored',
  B2CCancelledSubscription = 'Cancelled B2C Subscription',
  B2CRestoredSubscription = 'Restored B2C Subscription',
  B2CRequestedHelp = 'Requested B2C Help',
  B2CSelectPlanInitialPreSelection = 'B2C Select Plan: Initial Pre-selection',
  B2CSelectPlanPreSelectionMax20 = 'B2C Select Plan: Pre-selection max=20',
  B2CSelectPlanChangeSubscriptionType = 'B2C Select Plan: Change Subscription Type',
  B2CCheckoutPageNavigateBack = 'B2C Checkout Page: Navigate back',
  B2CCheckoutPageAddPromoCode = 'B2C Checkout Page: Add promo code',
  B2CCheckoutPageRemovePromoCode = 'B2C Checkout Page: Remove promo code',
  B2CCheckoutPagePurchase = 'B2C Checkout Page: Purchase',
  B2CManageSubscriptionRemoveProductLearnerFromSubscription = 'B2C Manage Subscription: Remove Product/Learner From Subscription',
  B2CManageSubscriptionReactivateLearner = 'B2C Manage Subscription: Reactivate Learner',
  B2CManageSubscriptionCancel = 'B2C Manage Subscription: Cancel',
  B2CManageSubscriptionRenew = 'B2C Manage Subscription: Renew',
  B2CManageSubscriptionAdditionalProductPurchased = 'B2C Manage Subscription: Additional Product Purchased',
  B2CManageSubscriptionSwitchPlan = 'B2C Manage Subscription: Switch Plan',
  B2CManageSubscriptionViewProductInfo = 'B2C Manage Subscription: View Product Info',
  B2CLearnerOpensMockTest = 'B2C Learner Opens Mock Test',
  B2CLearnerResumesMockTest = 'B2C Learner Resumes Mock Test',
  B2CMockTestGuardianAssignsMockTestsToLearner = 'B2C Mock Test: Guardian Assigns Mock Tests To Learner',
  B2CMockTestGuardianUnassignsMockTestFromLearner = 'B2C Mock Test: Guardian Unassigns Mock Test From Learner',
  // StudySession -
  StudySessionQuestionAction = 'Question Action',
  StudySessionStarted = 'Study Session Started',
  StudySessionEndModalClicked = 'Study Session End Modal Clicked',
  StudySessionRevealAnswer = 'Study Session Reveal Answer',
  StudySessionDocumentViewed = 'Document Viewed',
  StudySessionCompleted = 'Study Session Completed',
  StudySessionCourseCompletionDownloaded = 'Course Completion Downloaded',
  StudySessionContentRated = 'Content Rated',
  AccessibleContentDownloadedBPP = 'Accessible content downloaded (BPP)',
  StudySessionStartedWithUnknown = 'Study Session Unknown Started From',

  // Adaptive Assessment
  AssessmentStartScreenCancel = 'Assessment Start Screen Cancel',
  AssessmentStartVideoAction = 'Assessment Start Video Action',
  AssessmentSessionStart = 'Assessment Session Started',
  AssessmentSessionResumeUnfinished = 'Assessment Session Resume Unfinished',
  AssessmentSessionQuestionAction = 'Assessment Session Question Action',
  AssessmentSessionLastQuestionAnswered = 'Assessment Last Question Answered',
  AssessmentSessionInformationAction = 'Assessment Session Information Action',
  AssessmentSessionNavigateAwayAttempt = 'Assessment Session Navigate Away Attempt',
  AssessmentSessionNavigateAwayCancel = 'Assessment Session Navigate Away Cancel',
  AssessmentSessionSubmitWithInsufficientWords = 'Assessment Session Submit With Insufficient Words',

  // Learn
  LearnTestPracticeNuggetFilter = 'Learn Test Practice Nugget Filter',
  LearnTestPracticeStrandSelection = 'Learn Test Practice Strand Selection',
  LearnCourseNuggetFilter = 'Learn Course Nugget Filter',
  LearnCourseStrandSelection = 'Learn Course Strand Selection',

  // Media
  ContentMediaVideoSubtitlesChanged = 'Content Media Video Subtitles Changed',

  // RLP -
  RLPNuggetStarted = 'RLP Nugget Started',
  RLPFilterApplied = 'RLP Filter Applied',
  // Are you there modal
  StudySessionInactivityModalSeen = 'Inactivity Modal Seen',
  StudySessionInactivityModalClosed = 'Inactivity Modal Closed',
  // ELA Mood
  ElaMoodSelected = 'Emotion Selected',
  // Teacher Dashboard
  TeachCourseViewed = 'Teach Course Viewed',
  TeachStudentSelected = 'Teach Student Selected',
  TeachCourseInterventions = 'Teach Course Interventions',
  TeachCourseMarkbook = 'Teach Course Markbook',
  TeachCourseMarkbookStrandSelected = 'Teach Course Markbook Strand Selected',
  TeachCourseMarkbookDownload = 'Teach Course Markbook Download',
  TeachCourseMarkbookMenuOptions = 'Teach Course Markbook Menu Options',
  TeachAssignmentMarkbookMenuOptions = 'Teach Assignment Markbook Menu Options',
  MarkbookCustomised = 'Markbook Customised',
  TeacherDashboardStudentOverviewTimeMetricChanged = 'Teacher Dashboard Student Overview Time Metric Changed',
  TeacherDashboardStudentOverviewTimePeriodChanged = 'Teacher Dashboard Student Overview Time Period Changed',
  TeacherDashboardStudentOverviewMetricChanged = 'Teacher Dashboard Student Overview Metric Changed',
  TeacherDashboardStudentOverviewQLAViewed = 'Teacher Dashboard Student Overview QLA Viewed',
  TeacherDashboardStudentOverviewCalendarMonthChanged = 'Teacher Dashboard Student Overview Calendar Month Changed',
  TeacherDashboardStudentOverviewCourseClicked = 'Teacher Dashboard Student Overview Course Clicked',
  TeacherDashboardStudentOverviewStudentSettingsClicked = 'Teacher Dashboard Student Overview Student Settings Clicked',
  TeacherDashboardOverviewTimeFilterChanged = 'Teacher Dashboard Overview Time Filter Changed',
  TeacherDashboardOverviewStudentSelected = 'Teacher Dashboard Overview Student Selected',
  TeacherDashboardOverviewTableSortChanged = 'Teacher Dashboard Overview Table Sort Changed',
  TeacherDashboardOverviewSubjectFiltersChanged = 'Teacher Dashboard Overview Subject Filters Changed',
  TeacherDashboardOverviewCourseSelected = 'Teacher Dashboard Overview Course Selected',
  TeacherDashboardCoursesCourseChanged = 'Teacher Dashboard Courses Course Changed',
  TeacherDashboardCoursesTimeFilterChanged = 'Teacher Dashboard Courses Time Filter Changed',
  TeacherDashboardCoursesStudentSelected = 'Teacher Dashboard Courses Student Selected',
  TeacherDashboardCoursesActivityTableSortChanged = 'Teacher Dashboard Courses Activity Table Sort Changed',
  TeacherDashboardCoursesNuggetsTableSortChanged = 'Teacher Dashboard Courses Nuggets Table Sort Changed',
  TeacherDashboardCoursesViewMarkbookClicked = 'Teacher Dashboard Courses View Markbook Clicked',
  TeacherDashboardAssignmentsFilterChanged = 'Teacher Dashboard Assignments Filter Changed',
  TeacherDashboardAssignmentsOptionChanged = 'Teacher Dashboard Assignments Option Changed',
  TeacherDashboardAssignmentsTableDownloaded = 'Teacher Dashboard Assignments Table Downloaded',
  TeacherDashboardAssignmentsTableSorted = 'Teacher Dashboard Assignments Table Sorted',
  TeacherDashboardAssignmentDelete = 'Teacher Dashboard Assignment Delete',
  TeacherDashboardAssignmentDuplicate = 'Teacher Dashboard Assignment Duplicate',
  TeacherDashboardAssignmentDuplicateGroupClicked = 'Teacher Dashboard Assignment Group Duplicate Clicked',
  TeacherDashboardAssignmentDuplicateGroup = 'Teacher Dashboard Assignment Group Duplicate',
  TeacherDashboardClassListClassCategorySelected = 'Teacher Dashboard Class List Class Category Selected',
  TeacherDashboardClassListClassSelected = 'Teacher Dashboard Class List Class Selected',
  TeacherDashboardClassListSearched = 'Teacher Dashboard Class List Searched',
  TeacherDashboardNuggetsCourseSelected = 'Teacher Dashboard Nuggets Course Selected',
  TeacherDashboardNuggetsNuggetSelected = 'Teacher Dashboard Nuggets Nugget Selected',
  TeacherDashboardNuggetsOptionChanged = 'Teacher Dashboard Nuggets Option Changed',
  TeacherDashboardNuggetsTableSorted = 'Teacher Dashboard Nuggets Table Sorted',
  TeacherDashboardNuggetsLastAttemptOptionChanged = 'Teacher Dashboard Nuggets Last Attempt Option Changed',
  TeacherDashboardNuggetsTableDownloaded = 'Teacher Dashboard Nuggets Table Downloaded',
  TeacherDashboardStudentListStudentSelected = 'Teacher Dashboard Student List Student Selected',
  TeacherDashboardStudentListSearched = 'Teacher Dashboard Student List Searched',
  TeacherDashboardAssignmentsClassesWidgetItemClicked = 'Teacher Dashboard Assignments Classes Widget Item Clicked',
  TeacherDashboardAssignmentsStudentGroupWidgetItemClicked = 'TeacherDashboard Assignments Student Groups Widget Item Clicked',
  TeacherDashboardAssignmentsOverviewTableSortChanged = 'Teacher Dashboard Assignments Overview Table Sort Changed',
  // Teacher Dashboard - Assignments Tab
  TeacherDashboardAssignmentsTabItemClicked = 'Teacher Dashboard Assignments List Tab Item Clicked',
  TeacherDashboardAssignmentsTabNavigationChanged = 'Teacher Dashboard Assignments List Tab Navigation Changed',
  TeacherDashboardAssignmentsTabFilterChanged = 'Teacher Dashboard Assignments List Tab Filter Changed',
  TeacherDashboardAssignmentsTabFiltersCleared = 'Teacher Dashboard Assignments List Tab Filters Cleared',
  // Teacher Dashboard - Class Overview
  TeacherDashboardClassRecentAssignmentsNavigationChanged = 'Teacher Dashboard Class Recent Assignments Navigation Changed',
  // Teacher Dashboard  - Class Assignments
  TeacherDashboardAssignmentsTableSortChanged = 'Teacher Dashboard Assignments Table Sort Changed',
  TeacherDashboardAssignmentsTableNavigationChanged = 'Teacher Dashboard Assignments Table Navigation Changed',
  TeacherDashboardAssignmentsCreateButtonClicked = 'Teacher Dashboard Assignments Create Button Clicked',
  TeacherDashboardCreateAssignmentModal = 'Teacher Dashboard Create Assignment Modal',
  TeacherDashboardCreateAssignmentGroupModal = 'Teacher Dashboard Create Assignment Group Modal',
  TeacherDashboardAssignmentAddGroup = 'Teacher Dashboard Assignment Add Group',
  TeacherDashboardAssignmentRemoveGroup = 'Teacher Dashboard Assignment Remove Group',
  TeacherDashboardEditAssignmentModal = 'Teacher Dashboard Edit Assignment Modal',
  TeacherDashboardAssignmentSettingsAddNuggetsModal = 'Teacher Dashboard Assignment Settings Add Nuggets Modal',
  TeacherDashboardAssignmentSettingsAddNuggetsButtonClicked = 'Teacher Dashboard Assignment Settings Add Nuggets Button Clicked',
  TeacherDashboardAssignmentSettingsAssignClassesButtonClicked = 'Teacher Dashboard Assignment Settings Assign Classes Button Clicked',
  TeacherDashboardAssignmentSettingsRemoveClassesButtonClicked = 'Teacher Dashboard Assignment Settings Remove Class Button Clicked',
  TeacherDashboardAssignmentSettingsAssignStudentGroupButtonClicked = 'Teacher Dashboard Assignment Settings Assign Student Group Button Clicked',
  TeacherDashboardAssignmentSettingsRemoveStudentGroupsButtonClicked = 'Teacher Dashboard Assignment Settings Remove Student Group Button Clicked',
  TeacherDashboardAssignmentSettingsEditButtonClicked = 'Teacher Dashboard Assignment Settings Edit Button Clicked',
  TeacherDashboardAssignmentSettingsRemoveNuggetClicked = 'Teacher Dashboard Assignment Settings Remove Nugget Clicked',
  TeacherDashboardAssignmentsListAssignmentSelected = 'Teacher Dashboard Assignments List Assignment Selected',
  TeacherDashboardAssignmentNuggetsWidgetEditButtonClicked = 'Teacher Dashboard Assignment Nuggets Widget Edit Button Clicked',

  // Nugget Preview
  NuggetPreviewClicked = 'Nugget Preview Clicked',

  // Teacher Tools
  TeacherDashboardTeacherToolsTabClicked = 'Teacher Dashboard Teacher Tools Tab Clicked',
  TeacherDashboardTeacherToolsQuestionsGenerated = 'Teacher Dashboard Teacher Tools Questions Generated',
  TeacherDashboardTeacherToolsQuestionsGeneratedError = 'Teacher Dashboard Teacher Tools Questions Generated Error',
  TeacherDashboardTeacherToolsRevealAnswers = 'Teacher Dashboard Teacher Tools Reveal Answers',
  TeacherDashboardTeacherToolsRegenerateQuestionClicked = 'Teacher Dashboard Teacher Tools Regenerate Question Clicked',
  TeacherDashboardTeacherToolsRegenerateQuestionSubmitted = 'Teacher Dashboard Teacher Tools Regenerate Question Submitted',
  TeacherDashboardTeacherToolsQuestionCopied = 'Teacher Dashboard Teacher Tools Question Copied',
  TeacherDashboardTeacherToolsDownloadQuestionsSheet = 'Teacher Dashboard Teacher Tools Download Questions Sheet',
  TeacherDashboardTeacherToolsDownloadAnswersSheet = 'Teacher Dashboard Teacher Tools Download Answers Sheet',

  // Student Dashboard
  StudentDashboardTimeMetricChanged = 'Student Dashboard Time Metric Changed',
  StudentDashboardTimePeriodChanged = 'Student Dashboard Time Period Changed',
  StudentDashboardMetricChanged = 'Student Dashboard Metric Changed',
  StudentDashboardQLAViewed = 'Student Dashboard QLA Viewed',
  StudentDashboardSuggestedNuggetClicked = 'Student Dashboard Suggested Nugget Clicked',
  StudentDashboardCalendarMonthChanged = 'Student Dashboard Calendar Month Changed',
  StudentDashboardCourseClicked = 'Student Dashboard Course Clicked',
  StudentDashboardAssignmentsOptionChanged = 'Student Dashboard Assignments Option Changed',
  StudentDashboardAssignmentsTableSortChanged = 'Student Dashboard Assignments Table Sort Changed',
  StudentDashboardAssignmentsFilterChanged = 'Student Dashboard Assignments Filter Changed',
  StudentDashboardTestPracticeFilterByTopic = 'Student Dashboard Test Practice Filter By Topic',
  StudentDashboardCourseTypeFilter = 'Student Dashboard Course Type Filter',
  StudentDashboardTestPracticeCourseSelection = 'Student Dashboard Test Practice Course Selection',
  StudentDashboardTestPracticeSortNuggetList = 'Student Dashboard Test Practice Sort Nugget List',
  StudentDashboardAssignmentClicked = 'Student Dashboard Assignment Clicked',

  // Guardian Dashboard
  GuardianDashboardDependantListDependantSelected = 'Guardian Dashboard Dependant List Dependant Selected',
  GuardianDashboardTimeMetricChanged = 'Guardian Dashboard Time Metric Changed',
  GuardianDashboardTimePeriodChanged = 'Guardian Dashboard Time Period Changed',
  GuardianDashboardMetricChanged = 'Guardian Dashboard Metric Changed',
  GuardianDashboardQLAViewed = 'Guardian Dashboard QLA Viewed',
  GuardianDashboardCalendarMonthChanged = 'Guardian Dashboard Calendar Month Changed',
  GuardianDashboardCourseClicked = 'Guardian Dashboard Course Clicked',
  GuardianDashboardTestPracticeFilterByTopic = 'Guardian Dashboard Test Practice Filter By Topic',
  GuardianDashboardCourseTypeFilter = 'Guardian Dashboard Course Type Filterd',
  GuardianDashboardTestPracticeCourseSelection = 'Guardian Dashboard Test Practice Course Selection',
  GuardianDashboardTestPracticeSortNuggetList = 'Guardian Dashboard Test Practice Sort Nugget List',
  GuardianDashboardMockTestViewItem = 'Guardian Dashboard Mock Test View Test Item',

  // B2C Learner Dashboard
  LearnerDashboardMockTestViewItem = 'Learner Dashboard Mock Test View Test Item',

  // Badges and streaks
  AchievementsAchievementViewed = 'Achievements Achievement Viewed',
  AchievementsModalViewed = 'Achievements Modal Viewed',
  AchievementsModalViewAllClicked = 'Achievements Modal View All Clicked',
  AchievementsStreakModalClosed = 'Achievements Streak Modal Closed',
  AchievementsStreakModalViewed = 'Achievements Streak Modal Viewed',
  // Coginitive Message
  CognitiveMessageDismissed = 'Cognitive Message Dismissed',
  MSTeamsContent = 'MSTeams Tab Used',
  // Screen Filter
  ScreenFilterChanged = 'Screen Filter Changed',
  ScreenFilterMenuOpened = 'Screen Filter Menu Opened',

  // Teacher Assessment Tab
  AssessmentsCohortFilteredChange = 'Assessments Cohort Filtered Change',
  AssessmentsCohortSearchByName = 'Assessments Cohort Search by Name',
  AssessmentsCohortSorted = 'Assessments Cohort Sorted',
  AssessmentsCohortDataDownload = 'Assessment Cohort Data Download',
  AssessmentSessionExitAttempt = 'Assessment Session Exit Attempt',
  AssessmentsResetStudent = 'Assessments Reset Student',
  AssessmentsCertificatesGenerated = 'Assessments Certificates Generated',
  AssessmentsCertificatesDownloaded = 'Assessments Certificates Downloaded',
  AssessmentsIndividualCertificateDownloaded = 'Assessments Individual Certificate Downloaded',
  // Class Admin React
  AdminUsersListUserSelected = 'Class Admin Users List User Selected',
  AdminUsersListSearched = 'Class Admin Users List Searched',
  AdminClassListClassCategorySelected = 'Class Admin Classes List Class Category Selected',
  AdminClassListSearched = 'Class Admin Classes List Searched',
  AdminClassListClassSelected = 'Class Admin Class Selected',
  AdminClassCreated = 'Class Admin Class Created',
  AdminClassModal = 'Admin Class Modal',
  AdminMultiCourseAssignment = 'Admin Multi Course Assignment',
  AdminMultiCourseAssignmentCourseRemoved = 'Admin Multi Course Assignment Course Removed',
  AdminMultiCourseAssignmentClassRemoved = 'Admin Multi Course Assignment Class Removed',
  AdminStudentGuardianUpdated = 'Admin Student Guardian Updated',
  StudentPasswordChanged = 'Student Password Changed',
  AdminClassCodeGenerated = 'Admin Class Code Generated',
  AdminClassDeleted = 'Admin Class Deleted',
  AdminClassRenamed = 'Admin Class Renamed',
  AdminDataDownloaded = 'Admin Data Downloaded',
  AdminClassUpdated = 'AdminClassUpdated',
  ClassAdminUsersListUserSelected = 'Class Admin Users List User Selected',
  DeactivateStudentButtonClicked = 'Deactivate Student Button Clicked',
  ReactivateUserButtonClicked = 'Reactivate User Button Clicked',
  EditStudentDetailsButtonClicked = 'Edit Student Details Button Clicked',
  AddStudentButtonClicked = 'Create New Student button clicked',
  AddStudentCancelled = 'New Student Form cancelled',
  AddStudentSubmitted = 'New Student Form submitted',

  // Admin Onboarding
  AdminOrganisationListSelected = 'Admin Organisation List Selected',

  // Guardian Onboarding
  GuardiansOnboardingUploadModalOpened = 'Guardians Onboarding Upload Modal Opened',
  GuardiansOnboardingFileUploaded = 'Guardians Onboarding File Uploaded',
  GuardiansOnboardingCSVDownloadClicked = 'Guardians Onboarding CSV Download Clicked',
  GuardiansOnboardingCancelJobClicked = 'Guardians Onboarding Cancel Job Clicked',
  GuardiansOnboardingTriggerJobClicked = 'Guardians Onboarding Trigger Job Clicked',
  GuardiansOnboardingViewResultsClicked = 'Guardians Onboarding View Results Clicked',
  GuardiansOnboardingJobIdCopied = 'Guardians Onboarding Job Id Copied',

  // FE Onboarding
  FEOnboardingUploadModalOpened = 'FE Onboarding Upload Modal Opened',
  FEOnboardingNewConfigModalOpened = 'FE Onboarding New Config Modal Opened',
  FEOnboardingHelpModalOpened = 'FE Onboarding Help Modal Opened',
  FEOnboardingDisclaimerModalOpened = 'FE Onboarding Disclaimer Modal Opened',
  FEOnboardingTemplateGuideModalOpened = 'FE Onboarding Template Guide Modal Opened',
  FEOnboardingCSVTemplateDownloaded = 'FE Onboarding CSV Template Downloaded',
  FEOnboardingJobIdCopied = 'FE Onboarding Job Id Copied',
  FEOnboardingNewConfigSet = 'FE Onboarding New Config Set',
  FEOnboardingNewConfigValidateClicked = 'FE Onboarding New Config Validate Clicked',
  FEOnboardingNewConfigSaved = 'FE Onboarding New Config Saved',
  FEOnboardingFileCreatedAndTriggered = 'FE Onboarding File Created and Triggered',
  FEOnboardingViewResultsClicked = 'FE Onboarding View Results Clicked',
  IssuesModalOpened = 'Issues Modal Opened',

  // Admin Onboarding
  OnboardingImportModalOpened = 'Onboarding Import Modal Opened',
  OnboardingGroupCallModalOpened = 'Onboarding Group Call Modal Opened',
  OnboardingHelpModalOpened = 'Onboarding Help Modal Opened',
  OnboardingDisclaimerModalOpened = 'Onboarding Disclaimer Modal Opened',
  OnboardingTemplateGuideModalOpened = 'Onboarding Template Guide Modal Opened',
  OnboardingFileUploaded = 'Onboarding File Uploaded',
  OnboardingTriggerReplaced = 'Onboarding Trigger Replaced',
  OnboardingTriggerUploaded = 'Onboarding Trigger Uploaded',
  OnboardingCSVTemplateDownloaded = 'Onboarding CSV Template Downloaded',
  OnboardingTriggerDownloaded = 'Onboarding Trigger Downloaded',
  OnboardingTriggerTriggered = 'Onboarding Trigger Triggered',
  OnboardingTriggerCancelled = 'Onboarding Trigger Cancelled',

  // Assessments
  AssessmentsHubTestSelected = 'Assessments Hub Test Selected',
  AssessmentsPlayerGoBackClicked = 'Assessments Player Go Back Clicked',
  AssessmentsTimerToggled = 'Assessments Timer Toggled',
  AssessmentsInfoModalOpened = 'Assessments Info Modal Opened',
  AssessmentsSectionStarted = 'Assessments Section Started',
  AssessmentsQuestionViewed = 'Assessments Question Viewed',
  AssessmentsDocumentViewed = 'Assessments Document Viewed',
  AssessmentsQuestionAnswered = 'Assessments Question Answered',
  AssessmentsBreadcrumbClicked = 'Assessments Breadcrumb Clicked',
  AssessmentsSectionTimedOut = 'Assessments Section Timed Out',
  AssessmentsOverviewViewed = 'Assessments Overview Viewed',
  AssessmentsEndModalDismissed = 'Assessments End Modal Dismissed',
  AssessmentsEndSectionClicked = 'Assessments End Section Clicked',
  AssessmentsLeaveAttempted = 'Assessments Leave Attempted',
  AssessmentsPreviousContentNavigated = 'Assessments Previous Content Navigated',
  AssessmentsMockTestEndModalClosed = 'Assessments Mock Test End Modal Closed',
  // Account switching
  AccountSwitchModalOpen = 'Account Switch Modal Open',
  AccountSwitchModalClose = 'Account Switch Modal Close',
  AccountSwitchToGuardian = 'Account Switch To Guardian Attempt',
  AccountSwitchToLearner = 'Account Switch To Learner Attempt',
  AccountSwitchFailed = 'Account Switch Failed',
  AccountSwitchCompleted = 'Account Switch Completed',
  ProductSwitchPromotionModalOpen = 'Product Switch Promotion Modal Open',
  ProductSwitchPromotionModalClose = 'Product Switch Promotion Modal Close',
  ProductSwitchPromotionModalContinue = 'Product Switch Promotion Modal Continue',
  // SmartIA View Written Answer
  ViewWrittenAnswerFeedbackLinkClicked = 'View Written Answer Feedback Link Clicked',
  ViewWrittenAnswerModalOpened = 'View Written Answer Modal Opened',
  ViewWrittenAnswerModalViewed = 'View Written Answer Modal Viewed',
  ViewWrittenAnswerModalClosed = 'View Written Answer Modal Closed',
  ViewWrittenAnswerNavigateNext = 'View Written Answer Navigate Next',
  ViewWrittenAnswerNavigatePrevious = 'View Written Answer Navigate Previous',

  // LX My Journey
  BadgesWidgetClicked = 'Badges Widget Clicked',
  BadgesWidgetTooltipOpened = 'Badges Widget Tooltip Opened',
  StreaksWidgetTooltipOpened = 'Streaks Widget Tooltip Opened',
  RecommendedNuggetStarted = 'Recommended Nugget Started',
  RecommendedNuggetRefreshed = 'Recommended Nugget Refreshed',
  RecommendedNuggetTooltipOpened = 'Recommended Nugget Tooltip Opened',
  B2CLearnerMockTestTooltipOpened = 'B2C Learner Mock Test Tooltip Opened',
  SubjectCardClicked = 'Subject Card Clicked',
  SubjectProgressTooltipOpened = 'Subject Progress Tooltip Opened',
  SubjectActivityTooltipOpened = 'Subject Activity Tooltip Opened',
  TopicsListSearched = 'Topics List Searched',
  TopicCardClicked = 'Topic Card Clicked',
  TopicProgressTooltipOpened = 'Topic Progress Tooltip Opened',
  TopicActivityTooltipOpened = 'Topic Activity Tooltip Opened',
  TopicRecommendationLinkClicked = 'Topic Recommendation Link Clicked',
  NuggetsListTooltipOpened = 'Nuggets List Tooltip Opened',
  NuggetsListSearched = 'Nuggets List Searched',
  NuggetsListFilterChanged = 'Nuggets List Filter Changed',
  NuggetCardClicked = 'Nugget Card Clicked',
  StudySessionEndModal = 'Study Session End Modal',
  StudentDashboardSubjectsCourseSelection = 'Student Dashboard Subjects Course Selection',
  GuardianDashboardSubjectsCourseSelection = 'Guardian Dashboard Subjects Course Selection',
  StudentDashboardSubjectsFilterByTopic = 'Student Dashboard Subjects Filter By Topic',
  GuardianDashboardSubjectsFilterByTopic = 'Guardian Dashboard Subjects Filter By Topic',
  GuardianDashboardSubjectsNuggetsFilterByType = 'Guardian Dashboard Subjects Nuggets Filter By Type',
  StudentDashboardSubjectsNuggetsFilterByType = 'Student Dashboard Subjects Nuggets Filter By Type',
  StudentDashboardSubjectsSortNuggetList = 'Student Dashboard Subjects Sort Nugget List',
  GuardianDashboardSubjectsSortNuggetList = 'Guardian Dashboard Subjects Sort Nugget List',
  GuardianDashboardSubjectsNuggetListSearched = 'Guardian Dashboard Subjects Nugget List Searched',
  StudentDashboardSubjectsNuggetListSearched = 'Student Dashboard Subjects Nugget List Searched',

  // Learner Assignments
  AssignmentDiagnosticNuggetAttemptLimitAlertModal = 'Assignment Diagnostic Nugget Attempt Limit Alert Modal',
  AssignmentNuggetClicked = 'Assignment Nugget Clicked',
  AssignmentLockedNuggetClicked = 'Assignment Locked Nugget Clicked',

  // Maths Keyboard - Guppy
  MathsKeyboardKeyClicked = 'Maths Keyboard Key Clicked',
}
