import { Button } from '@ctek/design-system';
import _get from 'lodash/get';
import { useContext, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { FormattedMessage } from 'react-intl';
import CustomDragLayer from '../../DragDrop/CustomDragLayer';
import DragSource from '../../DragDrop/DragSource';
import DropTarget from '../../DragDrop/DropTarget';
import { AlternativeBoardMatchingWithoutBox } from 'century-core/core-components/DraggableQuestionTypes/components/AlternativeBoardMatching/AlternativeBoardMatching';
import { ComposablePromptAnswerList } from 'century-core/core-components/DraggableQuestionTypes/components/PromptAnswerList/PromptAnswerList';
import PromptAnswerPair from 'century-core/core-components/DraggableQuestionTypes/components/PromptAnswerPair/PromptAnswerPair';
import UploadedImage from 'century-core/core-components/DraggableQuestionTypes/components/UploadedImage/UploadedImage';
import MatchingAnswerDraggable from 'layout/elementsLearn/MatchingAnswerDraggable/MatchingAnswerDraggable';
import SmallRichTextEditor from 'layout/textEditors/SmallRichTextEditor/SmallRichTextEditor';
import { emptyDelta } from 'century-core/core-utils/utils/quill/constants';
import ClickSelectAndDrop, { ClickSelectAndDropProvider } from '../../DragDrop/ClickSelectAndDrop/ClickSelectAndDropContext';
import ClickSelectDrop from '../../DragDrop/ClickSelectAndDrop/ClickSelectDrop';
import ClickSelectItem from '../../DragDrop/ClickSelectAndDrop/ClickSelectItem';
import ItemTypes from '../ItemTypes';
import CustomLayerImage from '../Labelling/CustomLayerImage';
import { NoImagePlaceholder } from '../Labelling/DroppableLabelPair';
import { AnswerAction } from '../Labelling/LabellingQuestionType';
import { AnsweredItem, MatchingOrLabellingQuestionTypeProps, PossibleAnswer } from '../../../typings/DraggableQuestionTypes';
import CachedImageUrlContext from 'century-core/media/hooks/useGetImagePublicUrlCache';
import useMatchingAndLabellingStateManager from '../useMatchingAndLabellingStateManager';
import { getPossibleAnswerById } from '../utils';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

const MatchingQuestionType = (props: MatchingOrLabellingQuestionTypeProps) => {
  const { setSelectedItem } = useContext(ClickSelectAndDrop);

  const matchingManager = useMatchingAndLabellingStateManager({ ...props, setSelectedItem });

  const { dragComponent, hideControls, cantSkip, onAnswersChanged } = props;
  const {
    answeredItems,
    getAnswers,
    getPossibleAnswers,
    getContent,
    handleDrop,
    isNoItemLabelled,
    handleAnswerSubmitted,
    isAllItemsLabelled,
    isDropped,
    nextQuestionLoading,
    removeIdFromAnsweredItems,
    resetAnswers,
  } = matchingManager;

  const answers = getAnswers();
  const possibleAnswers = getPossibleAnswers();
  const content = getContent();
  const quillDelta: object = _get(content, ['0', 'data'], emptyDelta);

  useEffect(() => {
    onAnswersChanged?.(answeredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answeredItems]);

  const customDragLayer = isMobile ? (
    dragComponent === ('text' as Ctek.MatchType) ? (
      <CustomDragLayer
        DragLayerElement={({ item }: { item: PossibleAnswer }) => <RenderMatchingAnswerDraggable possibleAnswer={item} />}
        elementWidthReferenceElement=".matching-answer-draggable"
      />
    ) : (
      <CustomDragLayer DragLayerElement={CustomLayerImage} elementWidthReferenceElement=".draggable-label-item" />
    )
  ) : null;

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} context={window}>
      <DropTarget
        accepts={[ItemTypes.ASSIGNED_LABEL]}
        onDrop={item => removeIdFromAnsweredItems(item.id)}
        dropItem={(isOverContainer: boolean, canDropContainer: boolean) => (
          <AlternativeBoardMatchingWithoutBox qa="matching-question-board">
            {{
              additionType: dragComponent,
              additionals: renderAdditionals(isDropped, possibleAnswers, dragComponent),
              footerControls: hideControls ? null : (
                <>
                  {!cantSkip && (
                    <Button
                      loading={nextQuestionLoading}
                      onClick={(evt: any) => handleAnswerSubmitted(evt, AnswerAction.Skip)}
                      classNameExt="idk"
                      qa="button-skip"
                    >
                      <FormattedMessage id="study-session-skip-question-btn" defaultMessage="I don't know" />
                    </Button>
                  )}
                  <Button loading={nextQuestionLoading} disabled={isNoItemLabelled()} onClick={resetAnswers} qa="button-reset">
                    <FormattedMessage id="reset-button" defaultMessage="Reset" />
                  </Button>
                  <Button
                    loading={nextQuestionLoading}
                    disabled={!isAllItemsLabelled()}
                    type="button"
                    onClick={(evt: any) => handleAnswerSubmitted(evt, AnswerAction.Answer)}
                    variant="secondary"
                    qa="button-submit"
                  >
                    <FormattedMessage id="study-session-submit-answer-btn" defaultMessage="Submit answer" />
                  </Button>
                </>
              ),
              matchingPairs: (
                <MatchingPairs
                  dragComponent={dragComponent}
                  onDrop={handleDrop}
                  answers={answers}
                  answeredItems={answeredItems}
                  possibleAnswers={possibleAnswers}
                />
              ),
              onClickDropAdditional: (id: string) => removeIdFromAnsweredItems(id),
              question: (
                <SmallRichTextEditor
                  onChange={() => {
                    return;
                  }}
                  value={quillDelta}
                  readonly={true}
                />
              ),
            }}
          </AlternativeBoardMatchingWithoutBox>
        )}
      />
      {customDragLayer}
    </DndProvider>
  );
};

export const ClickDropProviderMatchingQuestionType = (props: MatchingOrLabellingQuestionTypeProps) => {
  return (
    <ClickSelectAndDropProvider>
      <MatchingQuestionType {...props} />
    </ClickSelectAndDropProvider>
  );
};

const MatchingPairs = ({
  dragComponent,
  onDrop,
  answers,
  answeredItems,
  possibleAnswers,
}: {
  dragComponent: Ctek.MatchType;
  onDrop: (index: number, item: { id: string }) => void;
  answers: Ctek.Answer[];
  answeredItems: AnsweredItem[];
  possibleAnswers: PossibleAnswer[];
}) => {
  return (
    <ComposablePromptAnswerList>
      {{
        promptAnswerPairs: answers.map((answer, index) => {
          const relatedAnsweredItem = answeredItems.find(item => item.answerGroupAnswerId === answer.id);
          const possibleAnswer = relatedAnsweredItem
            ? getPossibleAnswerById(relatedAnsweredItem.possibleAnswerId, possibleAnswers)
            : undefined;
          return (
            <li className="prompt-answer-list__item" key={answer.id}>
              <DropTarget
                accepts={[ItemTypes.ASSIGNED_LABEL, ItemTypes.UNASSIGNED_LABEL]}
                onDrop={item => onDrop(index, item)}
                dropItem={(isOver: boolean, canDrop: boolean) => (
                  <ClickSelectDrop onDrop={(id: string) => onDrop(index, { id })}>
                    {dragComponent === ('text' as Ctek.MatchType) ? (
                      <TextMatchingPair key={answer.id} answer={answer} possibleAnswer={possibleAnswer} hover={isOver && canDrop} />
                    ) : (
                      <ImageMatchingPair key={answer.id} answer={answer} possibleAnswer={possibleAnswer} hover={isOver && canDrop} />
                    )}
                  </ClickSelectDrop>
                )}
                key={answer?.id?.substring(6) + '_' + possibleAnswer?.id}
              />
            </li>
          );
        }),
      }}
    </ComposablePromptAnswerList>
  );
};

const renderAdditionals = (isDropped: (id: string) => boolean, possibleAnswers: PossibleAnswer[], dragComponent: Ctek.MatchType) => {
  return possibleAnswers.map((possibleAnswer, index) => {
    return isDropped(possibleAnswer.id) ? (
      <div style={{ visibility: 'hidden' }}>
        {dragComponent === ('text' as Ctek.MatchType) ? (
          <RenderMatchingAnswerDraggable possibleAnswer={possibleAnswer} />
        ) : (
          <ImageMatching possibleAnswer={possibleAnswer} />
        )}
      </div>
    ) : (
      <DraggableAdditional dragComponent={dragComponent} possibleAnswer={possibleAnswer} />
    );
  });
};

export const RenderMatchingAnswerDraggable = ({
  possibleAnswer,
  hideDragHandle,
}: {
  possibleAnswer: PossibleAnswer;
  hideDragHandle?: boolean;
}) => {
  return (
    <MatchingAnswerDraggable key={possibleAnswer.id}>
      {{
        hideDragHandle,
        value: possibleAnswer.content[0].data,
      }}
    </MatchingAnswerDraggable>
  );
};

const TextMatchingPair = ({
  answer,
  possibleAnswer,
  hover,
}: {
  answer: Ctek.Answer;
  possibleAnswer: PossibleAnswer | undefined;
  hover: boolean;
}) => {
  return (
    <PromptAnswerPair classNameExt={hover ? 'prompt-answer-pair--hover' : undefined}>
      {{
        itemType: 'text' as Ctek.MatchType,
        pair: [
          <MatchingAnswerDraggable key={answer.id}>
            {{
              hideDragHandle: true,
              value: answer.content?.[0].data,
            }}
          </MatchingAnswerDraggable>,

          possibleAnswer ? (
            <DragSource
              dragItem={(isDragging: boolean) => (
                <ClickSelectItem id={possibleAnswer.id}>
                  <MatchingAnswerDraggable key={possibleAnswer.id}>
                    {{
                      value: possibleAnswer.content[0].data,
                    }}
                  </MatchingAnswerDraggable>
                </ClickSelectItem>
              )}
              type={ItemTypes.ASSIGNED_LABEL}
              id={possibleAnswer.id}
              item={possibleAnswer}
            />
          ) : (
            <MatchingAnswerDraggable key={`possible-answer=${answer.id}`}>
              {{
                hideDragHandle: true,
                value: emptyDelta,
              }}
            </MatchingAnswerDraggable>
          ),
        ],
      }}
    </PromptAnswerPair>
  );
};

const ImageMatchingPair = ({
  answer,
  possibleAnswer,
  hover,
}: {
  answer: Ctek.Answer;
  possibleAnswer: PossibleAnswer | undefined;
  hover: boolean;
}) => {
  const [answerGroupImgSrc, setAnsGroupImgSrc] = useState(null as string | null);
  const [possibleAnswerImgSrc, setPossibleAnswerImgSrc] = useState(null as string | null);
  const token = useAccessToken();
  const { getMediaPublicUrl } = useContext(CachedImageUrlContext);

  useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchUrls() {
      const ansGroupImgSrc = (await getMediaPublicUrl(answer.content?.[0].id as string, token)) as string;
      setAnsGroupImgSrc(ansGroupImgSrc);

      if (!possibleAnswer) {
        return;
      }
      const possibleAnsImgSrc = (await getMediaPublicUrl(possibleAnswer.content[0].id, token)) as string;
      setPossibleAnswerImgSrc(possibleAnsImgSrc);
    }
    // Execute the created function directly
    fetchUrls();
  }, [answer.content, getMediaPublicUrl, possibleAnswer, token]);

  return (
    <PromptAnswerPair classNameExt={hover ? 'prompt-answer-pair--hover' : undefined}>
      {{
        itemType: 'image',
        pair: [
          <UploadedImage key={answer.id}>
            {{
              imgSrc: answerGroupImgSrc || null,
            }}
          </UploadedImage>,
          possibleAnswer && possibleAnswerImgSrc ? (
            <DragSource
              dragItem={(isDragging: boolean) => (
                <ClickSelectItem id={possibleAnswer.id}>
                  <UploadedImage>
                    {{
                      imgSrc: possibleAnswerImgSrc,
                    }}
                  </UploadedImage>
                </ClickSelectItem>
              )}
              type={ItemTypes.ASSIGNED_LABEL}
              id={possibleAnswer.id}
              item={possibleAnswerImgSrc}
            />
          ) : (
            <NoImagePlaceholder />
          ),
        ],
      }}
    </PromptAnswerPair>
  );
};

export const ImageMatching = ({ possibleAnswer }: { possibleAnswer: PossibleAnswer }) => {
  const url = useImageUrl(possibleAnswer.content[0].id);

  return (
    <UploadedImage>
      {{
        imgSrc: url,
      }}
    </UploadedImage>
  );
};

const DraggableAdditional = ({ dragComponent, possibleAnswer }: { dragComponent: Ctek.MatchType; possibleAnswer: PossibleAnswer }) => {
  return dragComponent === ('text' as Ctek.MatchType) ? (
    <DragSource
      className="draggable-label-item draggable-label-item__active"
      dragItem={(isDragging: boolean) => (
        <ClickSelectItem id={possibleAnswer.id}>
          <RenderMatchingAnswerDraggable possibleAnswer={possibleAnswer} />
        </ClickSelectItem>
      )}
      type={ItemTypes.UNASSIGNED_LABEL}
      id={possibleAnswer.id}
      item={possibleAnswer}
    />
  ) : (
    <DraggableImageMatching possibleAnswer={possibleAnswer} />
  );
};

const DraggableImageMatching = ({ possibleAnswer }: { possibleAnswer: PossibleAnswer }) => {
  const url = useImageUrl(possibleAnswer.content[0].id);

  return (
    <DragSource
      className="draggable-label-item draggable-label-item__active"
      dragItem={(isDragging: boolean) => (
        <ClickSelectItem id={possibleAnswer.id}>
          <UploadedImage>
            {{
              imgSrc: url,
            }}
          </UploadedImage>
        </ClickSelectItem>
      )}
      type={ItemTypes.UNASSIGNED_LABEL}
      id={possibleAnswer.id}
      item={url ? url : undefined}
    />
  );
};

const useImageUrl = (id: string) => {
  const [url, setUrl] = useState(null as string | null);
  const token = useAccessToken();
  const { getMediaPublicUrl } = useContext(CachedImageUrlContext);

  useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchUrls() {
      const imageUrl = (await getMediaPublicUrl(id, token)) as string;
      setUrl(imageUrl);
    }
    // Execute the created function directly
    fetchUrls();
  }, [getMediaPublicUrl, id, token]);

  return url;
};
