import { getAthenaContentURL } from '../athenaV1';
import { POST } from 'century-core/core-apis/utils';

export interface AthenaCoursesMeta {
  data: {
    courses: AthenaCourseMeta[];
  };
}

export interface AthenaCourseMeta {
  name: string;
  id: string;
  description: string;
  isPublic: boolean;
  organisation: Ctek.Organisation & { id: string };
  subject: { id: string; name: string };
}

export interface CourseMeta extends AthenaCourseMeta {
  isChecked: boolean;
  isAssigned?: boolean;
  level?: { id: string; name: string };
}

export function retrieveAssignableCourses(token: string, signal?: AbortSignal) {
  const url = getAthenaContentURL('/content');
  const body = {
    query: `{
              courses(courseType: "standard", isPublished: true) {
                id
                name
                description
                organisation { id }
                isPublic
                subject {
                  id
                  name
                }
                level {
                  id
                  name
                }
              }
            }`,
  };
  return POST<AthenaCoursesMeta>({ url, token, body, signal }).catch((err: Error) => {
    throw Error(`Unable to fetch courses for this org, ${err}`);
  });
}
