import { useState } from 'react';
import { read, write } from 'century-core/core-utils/utils/localStorage/localStorage';

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = read(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      write(key, JSON.stringify(valueToStore));
    } catch (error) {
      throw error;
    }
  };
  return [storedValue, setValue] as const;
}
