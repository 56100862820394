import { Auth } from 'century-core/entities/Auth/Auth';

/**
 * Returns an array with all of the user orgs.
 *
 * If the token is null, it returns an empty object.
 */
export const getTokenOrgs = (auth: Auth): Ctek.JWTDataOrganisation[] => auth.accessTokenData?.context.organisations || [];

/**
 * Gets user main org.
 *
 * Returns organisation with isPrimary field being true, if there's none then return first organisation.
 * Returns undefined in case there's no organisation in token's list
 */
export const getTokenMainOrg = (auth: Auth): Ctek.JWTDataOrganisation | undefined => {
  const orgs = getTokenOrgs(auth);

  if (orgs.length === 0) {
    return undefined;
  }

  const primaryOrg = orgs.find(org => org.isPrimary === true);

  return primaryOrg || orgs[0];
};

/**
 * Gets user main org id from token according to token version.
 *
 * If the token is null, it returns an empty object. If the org is null it returns an empty string.
 */
export const getTokenMainOrgId = (auth: Auth): string => {
  const mainOrg: Ctek.JWTDataOrganisation | undefined = getTokenMainOrg(auth);
  return mainOrg?.organisationId || '';
};

/**
 * Gets user main org name from token according to token version.
 *
 * If the token is null, it returns an empy object. If the org is null it returns an empty string.
 */
export const getTokenMainOrgName = (auth: Auth): string => {
  const mainOrg: Ctek.JWTDataOrganisation | undefined = getTokenMainOrg(auth);
  return mainOrg?.organisationName || '';
};

/**
 * Gets user main org type from token according to token version.
 *
 * If the token is null, it returns N/A. If the org is null it returns NA.
 */
export const getTokenMainOrgType = (auth: Auth): string => {
  return auth.accessTokenData?.context.settings.organisationType || 'N/A';
};

/**
 * Check user main org is among the list of provided organisation ids.
 *
 * If organisationIds is undefined or empty, it returns false.
 */
export const checkIsUserMainOrg = (auth: Auth, organisationIds?: string[]): boolean => {
  if (!organisationIds?.length) {
    return false;
  }

  const mainOrgId = getTokenMainOrgId(auth);
  return organisationIds.some(org => org === mainOrgId);
};
