import { parseAPIError } from 'century-core/core-apis/fetch/utils';
import { Errors, localisedErrors } from 'century-core/entities/ErrorMessage/Errors';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { registerB2CAdmin as register, RegisterB2CAdminPayload } from '../cms/b2cDashboard';

export async function registerB2CAdmin(inputUser: RegisterB2CAdminPayload): Promise<{ _id: string; id: string }> {
  try {
    const user = await register(inputUser);
    return user;
  } catch (err) {
    throw parseAPIError(
      err,
      new ErrorMessage<Errors>('localised-errors-b2c-register-error', localisedErrors['localised-errors-b2c-register-error'])
    );
  }
}
