export enum B2CDataLayerEvent {
  REGISTRATION_FORM = 'registration_form',
  ACCOUNT_CREATED = 'account_created',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  APP_PAYMENT = 'app_payment',
  ADD_PAYMENT_INFO = 'add_payment_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  START_TRIAL = 'start_trial',
  CANCEL_TRIAL = 'cancel_trial',
  RENEW_TRIAL = 'renew_trial',
  CANCEL_SUBSCRIPTION = 'cancel_subscription',
  RENEW_SUBSCRIPTION = 'renew_subscription',
  REMOVE_LEARNER = 'remove_learner',
  REACTIVATE_LEARNER = 'reactivate_learner',
}

export interface DataLayerEventObjectItem<T> {
  item_name: T;
  item_id: T;
  item_brand: string;
  item_category: string;
  quantity?: number;
  price: number;
  currency: string;
}

export interface DataLayerEventEcommercePurchaseObject<T> extends DataLayerEventEcommerceObject<T> {
  currency: string;
  value: number;
  transaction_id: string;
  coupon: string;
  action: 'update' | 'create' | 'cancel';
  subscription_id: string;
}

export interface DataLayerEventEcommerceObject<T> extends DataLayerEventObject {
  ecommerce: {
    payment_type?: 'card'; // only this option for now
    items: DataLayerEventObjectItem<T>[];
  } | null;
}

export interface DataLayerEventObject {
  event: string;
}

export interface DataLayerUserIdObject {
  user_id: string;
}

function initialiseDataLayer() {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
}

/**
 * Push to window.dataLayer
 * @param object
 */
export function push<T>(
  object: DataLayerUserIdObject | DataLayerEventObject | DataLayerEventEcommerceObject<T> | DataLayerEventEcommercePurchaseObject<T>
) {
  initialiseDataLayer();
  window.dataLayer?.push?.({ ecommerce: null } as DataLayerEventEcommerceObject<null>);
  window.dataLayer?.push?.(object);
}

const dataLayerLib = { push };

export default dataLayerLib;
