import { useLocalStorage } from 'century-core/core-utils/hooks/useLocalStorage';
import React from 'react';

export type ViewConfig = typeof defaultValue
export type ViewConfigOption = keyof ViewConfig

const defaultValue = {
  hasBar: true,
  hasNumber: true,
  hasSymbol: false,
  hasColouredCell: true,
  hideUsersColumn: false,
  showAttemptsCounter: true,
  showAttemptedNuggets: true,
};

const fallBackToNumber: ViewConfigOption[] = ['hasBar', 'hasNumber', 'hasSymbol', 'hasColouredCell'];

export const useMarkbookViewConfig = () => {
  const [viewConfig, setViewConfig] = useLocalStorage('completionTable.menuSettings', defaultValue);

  const toggleViewConfig = React.useCallback((configOption: ViewConfigOption) => {
    const newViewConfig = { 
      ...viewConfig, 
      [configOption]: !viewConfig[configOption] 
    };

    // toggle between hasSymbol and hasColouredCell when their values change
    if (configOption === 'hasSymbol' && newViewConfig.hasSymbol && newViewConfig.hasColouredCell) {
      newViewConfig.hasColouredCell = false;
    } else if (configOption === 'hasColouredCell' && newViewConfig.hasColouredCell && newViewConfig.hasSymbol) {
      newViewConfig.hasSymbol = false;
    }

    // Set hasNumber to true when all are false.
    const forceNumberOption = fallBackToNumber.every(option => !newViewConfig[option])
    if (forceNumberOption) {
      newViewConfig.hasNumber = true;
    }

    setViewConfig(newViewConfig);

    return newViewConfig;
  },[setViewConfig, viewConfig])

    return [viewConfig, toggleViewConfig] as const
}