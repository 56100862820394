import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { CustomThemeProvider } from 'century-core/core-auth/common/CustomThemeContext';
import Internationalize from 'components/Internationalization/Internationalize';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/utils';
import * as katex from 'katex';
import 'katex/dist/katex.min.css';
import { Component, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import * as microsoftTeams from '@microsoft/teams-js';
import AppVersionDetails from './century-core/user-preferences/AppVersionDetails/AppVersionDetails';
import { Spinner } from './century-core/core-components/Spinner/Spinner';
import { ConnectedProps } from './ContainerApp';
import { isLoggedIn } from 'century-core/core-auth/utils';
import { ApiProvider } from 'century-core/core-apis/ApiProvider';
import { AuthProvider } from 'century-core/core-auth/AuthContext';
import { UserProfileProvider } from 'century-core/core-auth/components/UserProfile/UserProfileContext';
import { getRedirectRouteByRole } from 'century-core/core-auth/utils';
import { GtmProvider } from 'century-core/core-utils/GtmContext';
import { LearnerProductContextProvider } from 'century-core/core-subscription/hooks/LearnerProductContext';
import { SubscriptionContextProvider } from 'century-core/core-subscription/hooks/SubscriptionContext';
import { LogrocketContextProvider } from 'century-core/core-subscription/hooks/LogrocketContext';
import { ClassCategoriesProvider } from 'century-core/core-utils/contexts/class-categories.context';
import { PartnerSource } from 'century-core/core-subscription/types';

window.katex = katex;

declare global {
  interface Window {
    auth0: Auth0Client;
    fcWidget: any;
    katex: any;
  }
  const rendereSuiteLinksV2: any;
}

const B2CRegistration = lazy(() => import('./components/B2CRegistration/Registration'));
const ContainerAppTemplate = lazy(() => import('./components/AppTemplate/ContainerAppTemplate'));
const MSTeamsContent = lazy(() => import('./century-core/features-msteams/MSTeamsContent/MSTeamsContent'));
const MSTeamsUnknown = lazy(() => import('./century-core/features-msteams/MSTeamsUnknown/MSTeamsUnknown'));

let isMicrosoftTeams = false;
function setMicrosoftTeamsContext() {
  microsoftTeams.initialize();
  microsoftTeams.appInitialization.notifySuccess();
  microsoftTeams.getContext(() => {
    isMicrosoftTeams = true;
  });
}

let checkedRoute = false;

export default class App extends Component<ConnectedProps> {
  constructor(props: ConnectedProps) {
    super(props);
    setMicrosoftTeamsContext();
  }

  public render() {
    return (
      <AuthProvider>
        <ApiProvider>
          <UserProfileProvider>
            <LearnerProductContextProvider>
              <SubscriptionContextProvider>
                <LogrocketContextProvider>
                  <CustomThemeProvider>
                    <Internationalize>
                      <AppVersionDetails>
                        <GtmProvider>
                          <Suspense fallback={<Spinner type="fullpage" />}>
                            <ClassCategoriesProvider>
                              <Switch>
                                {this.redirectLoggedUserOnCertainRoutes()}
                                <Route path="/msteams-content">
                                  <MSTeamsContent />
                                </Route>
                                <Route path="/msteams-unknown">
                                  <MSTeamsUnknown />
                                </Route>
                                <Route path="/registration">
                                  <B2CRegistration />
                                </Route>
                                <Route path="/bond">
                                  <B2CRegistration branding={PartnerSource.Bond} />
                                </Route>
                                <Route>
                                  <ContainerAppTemplate />
                                </Route>
                              </Switch>
                            </ClassCategoriesProvider>
                          </Suspense>
                        </GtmProvider>
                      </AppVersionDetails>
                    </Internationalize>
                  </CustomThemeProvider>
                </LogrocketContextProvider>
              </SubscriptionContextProvider>
            </LearnerProductContextProvider>
          </UserProfileProvider>
        </ApiProvider>
      </AuthProvider>
    );
  }

  public async componentDidUpdate(prevProps: ConnectedProps) {
    // We setLocale/phraseApp tool in componentDidUpdate as it depends on state.auth and this may not be loaded yet.
    const { location } = this.props;
    // Microsoft MS Teams app context
    // This context is set to set the current url and allow users to reload and stay in the same location.
    const isMSTeamsRoute = window.location.pathname.includes('/msteams-');
    if (isMicrosoftTeams && !isMSTeamsRoute) {
      const prevUrl = prevProps.location.pathname;
      if (prevUrl !== location.pathname && prevUrl !== '/') {
        const url = `${window.location.protocol}//${window.location.host}`;
        microsoftTeams.setFrameContext({
          contentUrl: `${url}${location.pathname}${location.search}`,
          websiteUrl: `${url}${location.pathname}${location.search}`,
        });
      }
    }
  }

  /**
   * Checks if you are logged in in any of the 'unauthorizedRoutes'
   *
   * We only need to check this once (hence the 'checkedRoute') when user lands there on the first time when loading
   * any of these urls (user can only type these routes, there are no internal links to it).
   *
   * After first load and check, if user's allow, we whouldn't check this in any re-render, as this could impact in
   * the logic, like in registering a user in '/registration' or '/bond'
   */
  private redirectLoggedUserOnCertainRoutes(): any {
    const authFromLocalStorage = parseAuthFromLocalstorage();
    if (!checkedRoute && authFromLocalStorage && isLoggedIn(authFromLocalStorage)) {
      const unauthorizedRoutes: string[] = ['/registration', '/bond', '/msteams-content', 'msteams-unknown'];

      return unauthorizedRoutes.map(
        route => window.location.pathname.includes(route) && window.location.assign(getRedirectRouteByRole(authFromLocalStorage))
      );
    }
    checkedRoute = true;
  }
}
