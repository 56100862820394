import { Button, DropdownSimple, dropdownIcon, FilterDropdownList, SwitchRow } from '@ctek/design-system';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage } from 'react-intl';
import { ViewConfig, ViewConfigOption } from '../../common/hooks/useMarkbookViewConfig';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';

interface Props {
  pageArea: string;
  viewConfig: ViewConfig;
  changeViewConfig: (option: ViewConfigOption) => void;
  showAttemptsCounterAvailable?: boolean;
  hideStudentNamesAvailable?: boolean;
}

export const MarkbookOptions = ({
  pageArea,
  viewConfig,
  changeViewConfig,
  showAttemptsCounterAvailable = true,
  hideStudentNamesAvailable = true,
}: Props) => {
  const onToggle = (opening: boolean) => {
    if (opening === false) {
      mixpanel.track(MixpanelEventTypes.MarkbookCustomised, {
        [MixpanelKeys.PageArea]: pageArea,
        ...viewConfig,
      });
    }
  }
  return (
    <DropdownSimple qa="button-menu-options-open" onToggle={onToggle} alignEnd={true}>
      <DropdownSimple.Toggle qa="button-menu-options-button">
        <Button size="small" variant="muted" icon={dropdownIcon} reversed={true}>
          <FormattedMessage id="completion-table-menu-options" defaultMessage={'Options'} />
        </Button>
      </DropdownSimple.Toggle>
      <DropdownSimple.Content>
        <FilterDropdownList qa="menu-options">
          <FilterDropdownList.Item>
            <SwitchRow qa="switch-bars">
              <SwitchRow.Label>
                <FormattedMessage id="completion-table-menu-show-bars" defaultMessage={'Show Bars'} />
              </SwitchRow.Label>
              <input type="checkbox" checked={viewConfig.hasBar} onChange={() => changeViewConfig('hasBar')} />
            </SwitchRow>
          </FilterDropdownList.Item>
          <FilterDropdownList.Item>
            <SwitchRow qa="switch-numbers">
              <SwitchRow.Label>
                <FormattedMessage id="completion-table-menu-show-numbers" defaultMessage={'Show Numbers'} />
              </SwitchRow.Label>
              <input type="checkbox" checked={viewConfig.hasNumber} onChange={() => changeViewConfig('hasNumber')} />
            </SwitchRow>
          </FilterDropdownList.Item>
          <FilterDropdownList.Item>
            <SwitchRow qa="switch-symbols">
              <SwitchRow.Label>
                <FormattedMessage id="completion-table-menu-show-symbols" defaultMessage={'Show Symbols'} />
              </SwitchRow.Label>
              <input type="checkbox" checked={viewConfig.hasSymbol} onChange={() => changeViewConfig('hasSymbol')} />
            </SwitchRow>
          </FilterDropdownList.Item>
          <FilterDropdownList.Item>
            <SwitchRow qa="switch-colors">
              <SwitchRow.Label>
                <FormattedMessage id="completion-table-menu-coloured-cells" defaultMessage={'Coloured cells'} />
              </SwitchRow.Label>
              <input type="checkbox" checked={viewConfig.hasColouredCell} onChange={() => changeViewConfig('hasColouredCell')} />
            </SwitchRow>
          </FilterDropdownList.Item>
          {showAttemptsCounterAvailable && (
            <FilterDropdownList.Item>
              <SwitchRow qa="switch-colors">
                <SwitchRow.Label>
                  <FormattedMessage id="completion-table-menu-show-attempts-counter" defaultMessage={'Show attempts counter'} />
                </SwitchRow.Label>
                <input type="checkbox" checked={viewConfig.showAttemptsCounter} onChange={() => changeViewConfig('showAttemptsCounter')} />
              </SwitchRow>
            </FilterDropdownList.Item>
          )}
          {hideStudentNamesAvailable && (
            <FilterDropdownList.Item>
              <SwitchRow qa="switch-users">
                <SwitchRow.Label>
                  <FormattedMessage id="completion-table-menu-hide-users" defaultMessage={"Hide students' names"} />
                </SwitchRow.Label>
                <input type="checkbox" checked={viewConfig.hideUsersColumn} onChange={() => changeViewConfig('hideUsersColumn')} />
              </SwitchRow>
            </FilterDropdownList.Item>
          )}
        </FilterDropdownList>
      </DropdownSimple.Content>
    </DropdownSimple>
  );
};