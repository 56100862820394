import { FetchErrorWithData, reqJSON } from 'century-core/core-apis/fetch';
import { GET, PATCH, POST, DELETE, setUrlSearchParams } from 'century-core/core-apis/utils';
import { getAccountsUrl } from '../accountsV2';
import { chunk } from 'lodash';

export function getUser(userId: string, accessToken: string): Promise<Ctek.User> {
  const url = getAccountsUrl(`/users/${userId}`);

  return GET<Ctek.User>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to get user by id, ${err?.message}`);
  });
}

export function patchMe(
  accessToken: string,
  body:
    | Partial<Ctek.User>
    | { profile: { ids: { username: string } } }
    | { password: string }
    | { profile: { avatar: string } }
    | { profile: { selectedLocale: string } }
    | { profile: { groups: { organisations: Ctek.UserGroupOrg[] } } }
) {
  const url = getAccountsUrl('/users/me');

  return PATCH<{ id: string; _id: string }>({ url, token: accessToken, body });
}

/** This patches to user by passing userId as /me is more restricted and fails when creating a class */
export function patchUser(
  accessToken: string,
  userId: string,
  body: Partial<Ctek.User> | { profile: { groups: { organisations: Ctek.UserGroupOrg[] } } }
) {
  const url = getAccountsUrl(`/users/${userId}`);

  return PATCH<{ id: string; _id: string }>({ url, token: accessToken, body }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to patch user by id, ${err?.message}`);
  });
}

export function createUser(
  accessToken: string,
  body: (Partial<Ctek.User> & { password: string }) | { profile: { groups: { organisations: Ctek.UserGroupOrg[] } } }
) {
  const url = getAccountsUrl('/users');

  return POST<{ id: string; _id: string }>({ url, token: accessToken, body }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to create user, ${err?.message}`);
  });
}

export function getStudentsByClassId(classId: string, orgId: string, accessToken: string): Promise<Ctek.User[]> {
  const url = getAccountsUrl('/users/');
  url.search += `&classes=${classId}`;
  url.search += '&select=personal,profile,contact,history';
  url.search += `&org=${orgId}`;
  url.search += '&role=student';

  return GET<Ctek.User[]>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to get students by classId, ${err?.message}`);
  });
}

export function getTeachersByClassId(classId: string, orgId: string, accessToken: string): Promise<Ctek.User[]> {
  const url = getAccountsUrl('/users/');
  url.search += `&classes=${classId}`;
  url.search += '&select=personal,profile,contact,history';
  url.search += `&org=${orgId}`;
  url.search += '&role=teacher';

  return GET<Ctek.User[]>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to get teachers by classId, ${err?.message}`);
  });
}

export function getUsersByRole(
  accessToken: string,
  orgId: string,
  role: string,
  skip: number = 0,
  name: string = '',
  limit: number = 20
): Promise<{ users: Ctek.User[]; count: number }> {
  const url = getAccountsUrl('/users/');
  url.search += '&select=personal,profile,contact,history';
  url.search += '&sort=history.thisVersion.updatedAt';
  url.search += `&org=${orgId}`;
  url.search += `&role=${role}`;
  url.search += `&limit=${limit}`;
  url.search += `&skip=${skip}`;

  if (!!name) {
    url.search += `&name=${name}`;
  }

  const reqOpts = {
    headers: { authorization: 'Bearer ' + accessToken },
  };

  return reqJSON<Ctek.User[]>(url.href, reqOpts, true)
    .then(res => ({
      users: res.data,
      count: parseInt(res.headers.get('x-total-count') || '0', 10),
    }))
    .catch((err: FetchErrorWithData) => {
      throw Error(`Unable to get users by role ${role}, ${err?.message}`);
    });
}

export function getMultipleUsersById(
  userIds: string[],
  accessToken: string,
  option: string = 'personal,contact,profile,isTest,isEnabled,history'
) {
  const idChunks = chunk(userIds, 20); // divide into chunks of 20 to process them in batches

  const promises = idChunks.map((ids: string[]) => {
    const url = getAccountsUrl('/users');
    url.search += `?ids=${ids.join()}`;
    url.search += `&select=${option}`;

    return GET<Ctek.User[]>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
      throw Error(`Unable to get users by ids, ${err?.message}`);
    });
  });

  return Promise.all(promises).then(res => res.flat());
}

export function getGuardiansByUserId(accessToken: string, userId: string): Promise<Ctek.User[]> {
  const url = getAccountsUrl(`/users/${userId}/guardians`);

  return GET<Ctek.User[]>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to get guardians by userId, ${err?.message}`);
  });
}

export function searchUsersByName(query: string, orgId: string, accessToken: string) {
  const url = getAccountsUrl('/users');
  url.search += `?org=${orgId}`;
  url.search += `&name=${query}`;
  url.search += '&select=personal,contact,profile';

  return GET<Ctek.User[]>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to search users with query, ${err?.message}`);
  });
}

export type GetUserOptions = Partial<{
  org: string; // search users in org id
  classes: string;
  class: string;
  select: string; // fields to be populated
  name: string; // search filtered by name
  search: string; // search filtered by name, same as above
  email: string; // search by email
  includeAnonymousUsers: boolean;
  role: Ctek.Roles;
  isEnabled: boolean;
  sort: string;
  // for pagination
  skip: number;
  limit: number;
}>;
export function getUsers(token: string, signal: AbortSignal, options: GetUserOptions = {}) {
  const url = getAccountsUrl('/users');
  setUrlSearchParams(url, options);

  const reqOpts = {
    headers: { authorization: `Bearer ${token}` },
    signal,
  };
  return reqJSON<Ctek.User[]>(url.href, reqOpts, true).then(res => ({
    users: res.data,
    count: parseInt(res.headers.get('x-total-count') || '0', 10),
  }));
}

export function addGuardianToUser(accessToken: string, userId: string, guardian: Ctek.User) {
  const url = getAccountsUrl(`/users/${userId}/guardians`);

  return POST<{ id: string; _id: string }>({ url, token: accessToken, body: guardian }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to add guardian to user, ${err?.message}`);
  });
}

export function getUserEmails(accessToken: string, email: string) {
  const url = getAccountsUrl();
  url.pathname += '/users';
  url.search += `?email=${email}`;

  return GET<Ctek.User[]>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    if (err.errorCode === 403) {
      // throwing this error here and catching in AddGuardianModal.tsx
      throw err;
    }
    throw Error(`Unable to get users by email, ${err?.message}`);
  });
}

/**
 * Removes the dependant from the guardian account and reloads the state
 */
export function removeGuardianFromUser(accessToken: string, userId: string, guardianId: string) {
  const url = getAccountsUrl(`/users/${userId}/guardians/${guardianId}`);

  if (!guardianId) {
    throw Error('Unable to delete. Missing guardianId.');
  }
  if (!userId) {
    throw Error('Unable to delete. Missing userId.');
  }

  return DELETE<{ id: string; _id: string }>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    throw Error(`Unable to remove guardian from user, ${err?.message}`);
  });
}

export function downloadStudentGuardiansCSV(accessToken: string, orgId: string) {
  const url = getAccountsUrl('/users/students-guardians-csv/');
  url.search += `?orgId=${orgId}`;

  return GET<{ url: string }>({ url, token: accessToken })
    .then(res => res.url)
    .catch((err: FetchErrorWithData) => {
      throw new Error(`Error: ${err.errorCode} - ${err?.message}`);
    });
}

export function getUserSummary(id: string, accessToken: string) {
  const url = getAccountsUrl(`/users/${id}/summary`);

  return GET<{ name: string; avatar: string }>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get user summary by id ${id}, ${err.message}`);
  });
}

export function deactivateUser(token: string, signal: AbortSignal, userId: string) {
  const url = getAccountsUrl(`/users/${userId}/deactivate`);
  return POST({ url, token, signal, body: {} });
}

export function reactivateUser(token: string, signal: AbortSignal, userId: string) {
  const url = getAccountsUrl(`/users/${userId}/activate`);
  return POST({ url, token, signal, body: {} });
}
