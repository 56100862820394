import { DateTime } from 'luxon';
import { useState } from 'react';
import * as React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Button, Heading, nextIcon, previousIcon } from '@ctek/design-system';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import mixpanel from 'mixpanel-browser';

interface Props {
  captionElement?: React.ReactElement;
  selectedDays: Date[];
  setNewDateRange: (start: string, end: string) => void;
  widgetMode: DashboardUserMode;
  userId: string;
}

export function ActivityCalendar(props: Props) {
  const now = DateTime.utc();
  const nowJSDate = now.toJSDate();
  const [displayedMonth, setDisplayedMonth] = useState(now);

  const getNewDateRangeData = (start: string, end: string) => {
    props.setNewDateRange(start, end);
  };

  const trackMonthChange = (start: DateTime) => {
    const selectedString = start.toISO();
    if (props.widgetMode === DashboardUserMode.STUDENT) {
      mixpanel.track(MixpanelEventTypes.StudentDashboardCalendarMonthChanged, { [MixpanelKeys.SelectedMonth]: selectedString });
    }
    if (props.widgetMode === DashboardUserMode.GUARDIAN) {
      mixpanel.track(MixpanelEventTypes.GuardianDashboardCalendarMonthChanged, {
        [MixpanelKeys.SelectedMonth]: selectedString,
        [MixpanelKeys.DependantId]: props.userId,
      });
    }
    if (props.widgetMode === DashboardUserMode.TEACHER) {
      mixpanel.track(MixpanelEventTypes.TeacherDashboardStudentOverviewCalendarMonthChanged, {
        [MixpanelKeys.SelectedMonth]: selectedString,
        [MixpanelKeys.StudentId]: props.userId,
      });
    }
  };

  const handlePreviousClick = (onPreviousClick: () => void) => {
    onPreviousClick();
    const start = displayedMonth.minus({ months: 1 }).startOf('month').toUTC();
    const end = displayedMonth.minus({ months: 1 }).endOf('month').toUTC();
    setDisplayedMonth(start);
    getNewDateRangeData(start.toISO(), end.toISO());
    trackMonthChange(start);
  };

  const handleNextClick = (onNextClick: () => void) => {
    onNextClick();
    const start = displayedMonth.plus({ months: 1 }).startOf('month').toUTC();
    const end = displayedMonth.plus({ months: 1 }).endOf('month').toUTC();
    setDisplayedMonth(start);
    getNewDateRangeData(start.toISO(), end.toISO());
    trackMonthChange(start);
  };

  const navbar = ({ onPreviousClick, onNextClick, month }: any) => {
    return (
      <div data-testid="widget--activity-calendar--heading" className="DayPicker-Controls">
        <Button
          icon={previousIcon}
          size="small"
          onClick={() => handlePreviousClick(onPreviousClick)}
          variant="secondary"
          qa="prev-month-button"
        />
        <Heading.ItemTitleBig>{DateTime.fromJSDate(month).toFormat('MMMM yyyy')}</Heading.ItemTitleBig>
        <Button
          icon={nextIcon}
          size="small"
          onClick={() => handleNextClick(onNextClick)}
          variant="secondary"
          disabled={DateTime.local().hasSame(month, 'month')}
          qa="next-month-button"
        />
      </div>
    );
  };

  const Weekday = ({ weekday, className }: any) => {
    const weekdayName = now.set({ weekday }).toFormat('EEEEE');
    return <div className={className}>{weekdayName}</div>;
  };

  const caption = () => {
    return null;
  };

  return (
    <DayPicker
      selectedDays={props.selectedDays}
      firstDayOfWeek={1}
      navbarElement={navbar}
      captionElement={caption}
      disabledDays={[{ after: nowJSDate }]}
      weekdayElement={<Weekday />}
    />
  );
}

export default ActivityCalendar;
