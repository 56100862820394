import { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Auth from 'century-core/entities/Auth/Auth';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';

import loadLocalStorage from './lib/loadLocalStorage';
import renewAccessToken from './lib/renewAccessToken';
import initialiseAuth0 from './lib/initialiseAuth0';
import { checkAccessTokenOnRouteChange } from './lib/checkAccessTokenOnRouteChange';
import {
  populateAuth,
  writeAuthToLocalstorage,
  scheduleRenewToken,
  addUserAndOrgIdToSentry,
  getTokenLoginSessionId,
  getTokenMainOrgId,
  getTokenMainOrgName,
  getTokenSub,
  getTokenSettingsLocale,
  getIsTestUser,
  getTokenMainOrgType,
  getPrimaryOrgRoles,
} from './utils';
import { getReleaseTag, getSubDomain } from 'century-core/core-utils/utils/config/config';
import { getDomainSettings } from 'century-core/core-apis/sentinel/auth/auth';
import { addExternalCustomMenu } from 'century-core/core-auth/utils/externalCustomMenu';

import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';

// eslint-disable-next-line import/no-restricted-paths
import { loggedIn } from '../../state/actions/auth/auth';
import mixpanel from 'mixpanel-browser';
import { usePostHog } from 'posthog-js/react';

const defaultData: Auth = {
  accessToken: undefined,
  accessTokenData: undefined,
  error: undefined,
  refreshToken: undefined,
  refreshTokenData: undefined,
  viewState: undefined,
};

interface AuthContextProps {
  auth: Auth;
  loadingAuth: boolean;
  orgSettings: Ctek.Accounts.DomainResponse | null;
  renewTokenTimer: NodeJS.Timeout;
  setAuthEverywhere: (auth: Auth) => void;
  createSession: (auth: Auth) => void;
  setError: (error: ErrorMessage<Errors> | undefined) => void;
  emailAddressRecovery: string;
  setEmailAddressRecovery: (email: string) => void;
}

export const AuthContext = createContext<AuthContextProps>({ auth: defaultData } as AuthContextProps);

export const useAuthContext = () => useContext(AuthContext);

export type AuthUpdateEvent = CustomEvent<{ auth: Auth }>;

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const posthog = usePostHog();

  const [auth, setAuth] = useState<Auth>(defaultData);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [orgSettings, setOrgSettings] = useState<Ctek.Accounts.DomainResponse | null>(null);
  const [emailAddressRecovery, setEmailAddressRecovery] = useState<string>('undefined');
  const renewTokenTimer = 0 as unknown as NodeJS.Timeout;

  // Load localStorage
  useEffect(() => {
    async function loadData() {
      const isMSTeamsRoute = window.location.pathname.includes('/msteams-');
      if (!isMSTeamsRoute) {
        const newAuth = await loadLocalStorage(renewTokenTimer, history.push, setAuthEverywhere);
        if (!!newAuth) {
          setAuthEverywhere(newAuth);
        }
        setLoadingAuth(false);
      }
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load subdomain data, add window eventListener's
  const firstRun = useRef(true);
  useEffect(() => {
    const popstateFunction = () => {
      checkAccessTokenOnRouteChange(auth, renewTokenTimer, setAuthEverywhere);
    };
    const visibilitychangeFunction = () => {
      if (document.visibilityState === 'visible') {
        checkAccessTokenOnRouteChange(auth, renewTokenTimer, setAuthEverywhere);
        loadDomainSettings();
      }
    };

    // TODO move loadDomainSettings to independent useEffect non depending on auth
    // check if 'visibilitychangeFunction' actually needs to call loadDomainSettings
    const loadDomainSettings = async () => {
      const subDomain = getSubDomain();
      const domainSettings = await getDomainSettings(subDomain);
      initialiseAuth0(domainSettings);
      setOrgSettings(domainSettings || null);
    };
    loadDomainSettings();

    if (firstRun.current && auth.accessToken) {
      window.addEventListener('popstate', popstateFunction);
      document.addEventListener('visibilitychange', visibilitychangeFunction);
    }
    return () => {
      window.removeEventListener('popstate', popstateFunction);
      document.removeEventListener('visibilitychange', visibilitychangeFunction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  // Load externals custom menu's and initialise mixpanel properties
  useEffect(() => {
    addExternalCustomMenu(auth);
  }, [auth]);

  useEffect(() => {
    // hack for notify polymer of auth changes
    if (document.querySelectorAll('polymer-to-react').length > 0) {
      const event = new CustomEvent<{ auth: Auth }>('authUpdated', { detail: { auth } });
      document.dispatchEvent(event);
    }
  }, [auth]);

  const setAuthEverywhere = useCallback(
    (newAuth: Auth) => {
      setAuth(newAuth);

      mixpanel.register({
        Locale: getTokenSettingsLocale(newAuth),
        [MixpanelKeys.LoginSessionId]: getTokenLoginSessionId(newAuth),
        [MixpanelKeys.OrganisationId]: getTokenMainOrgId(newAuth),
        [MixpanelKeys.OrganisationName]: getTokenMainOrgName(newAuth),
        [MixpanelKeys.UserId]: getTokenSub(newAuth),
        distinct_id: getTokenSub(newAuth),
      });

      // FIXME this dispatch here is only to make 'createApolloClient' work with the state.
      dispatch(loggedIn(newAuth));
    },
    [dispatch]
  );

  const createSession = useCallback(
    (newAuth: Auth) => {
      setLoadingAuth(true);
      const parsedAuth: Auth = newAuth.accessTokenData ? newAuth : populateAuth(newAuth);
      writeAuthToLocalstorage(parsedAuth);

      addUserAndOrgIdToSentry(parsedAuth);
      scheduleRenewToken(parsedAuth, () => renewAccessToken(parsedAuth, renewTokenTimer, setAuthEverywhere), renewTokenTimer);

      // This is split into two as some props we want to make immutable to stop them being overwritten by anyone
      mixpanel.register_once({
        [MixpanelKeys.IsTestUser]: getIsTestUser(parsedAuth),
        [MixpanelKeys.LoginSessionId]: getTokenLoginSessionId(parsedAuth) || 'N/A',
        [MixpanelKeys.OrganisationId]: getTokenMainOrgId(parsedAuth) || 'N/A',
        [MixpanelKeys.OrganisationName]: getTokenMainOrgName(parsedAuth) || 'N/A',
        [MixpanelKeys.UserId]: getTokenSub(parsedAuth),
        distinct_id: getTokenSub(parsedAuth),
      });
      
      // Don't call identify or group multiple times to save on requests to Posthog 
      if(!posthog._isIdentified()){
        posthog?.identify(getTokenSub(parsedAuth), {
          [MixpanelKeys.AppVersion]: getReleaseTag(),
          [MixpanelKeys.IsTestUser]: getIsTestUser(parsedAuth),
          [MixpanelKeys.Roles]: getPrimaryOrgRoles(parsedAuth) || 'N/A',
        })
        posthog?.register_once({
          [MixpanelKeys.UserId]: getTokenSub(parsedAuth),
        })
        
        // We're using the mixpanel keys here whilst in a transition period over to Posthog
        // Ideally we move this away from the app and have it on org creation in the API when we can.
        posthog?.group('organisation', getTokenMainOrgId(parsedAuth) || 'N/A', {
          name: getTokenMainOrgName(parsedAuth) || 'N/A', // Using the mixpanel property here causes Posthog to render the name as an ID in the UI.
          [MixpanelKeys.OrganisationType]: getTokenMainOrgType(parsedAuth) || 'N/A'
        })
      }

      setAuthEverywhere(parsedAuth);
      setLoadingAuth(false);
    },
    [setAuthEverywhere, posthog]
  );

  const setError = useCallback(
    (error: ErrorMessage<Errors>) => {
      setAuth(prevAuth => ({
        ...prevAuth,
        error,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renewTokenTimer]
  );

  return (
    <AuthContext.Provider
      value={{
        auth,
        loadingAuth,
        orgSettings,
        renewTokenTimer,
        setAuthEverywhere,
        createSession,
        setError,
        emailAddressRecovery,
        setEmailAddressRecovery,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
