import { GET, POST } from 'century-core/core-apis/utils';
import { getPalpatineUrl } from '../palpatine';

export function getStudyGroups(userId: string, accessToken: string) {
  const url = getPalpatineUrl(`/studygroups/users/${userId}`);
  url.searchParams.append('status', 'current')

  return GET<Ctek.StudyGroup[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get studygroups for user id ${userId}, ${err}`);
  });
}

export interface CtekJob {
  jobId: string;
  status: string;
  contentResponse: {
    resultIds: {
      contentId: string;
    }[];
  };
  contextIds: string;
  error: {
    errType: string;
    message: string;
  };
}

export async function waitForJob(jobId: string, accessToken: string, interval = 300): Promise<CtekJob> {
  try {
    const url = getPalpatineUrl(`/jobs/${jobId}`);
    const job = await GET<CtekJob>({ url, token: accessToken });

    if (job.status === 'waiting' || job.status === 'running') {
      return new Promise<CtekJob>(resolve => setTimeout(() => resolve(waitForJob(jobId, accessToken, interval)), interval));
    } else if (job.status === 'success' || job.status === 'failure') {
      return job;
    } else {
      throw job;
    }
  } catch (err) {
    throw Error(`Unable to resolve job, ${err.message}`);
  }
}

export interface MassAssignParams {
  organisationId: string;
  classIds: string[];
  courseIds: string[];
}

// Provided an object of type MassAssignParams, this function will return an object with a jobId property.
export async function massCourseseAssignment(accessToken: string, massStudyGroupContents: MassAssignParams) {
  const url = getPalpatineUrl('/massstudygroups');

  return await POST<{ jobId: string }>({ url, token: accessToken, body: massStudyGroupContents });
}

export async function createStudyGroup(studyGroup: Ctek.StudyGroup, accessToken: string) {
  try {
    const url = getPalpatineUrl('/studygroups');

    return await POST<{ jobId: string }>({ url, token: accessToken, body: studyGroup });
  } catch (err) {
    throw Error(`Unable to create study group, ${err}`);
  }
}
