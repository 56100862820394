import { fetchLabelsByIds } from 'century-core/core-apis/contentV2/labels/labels';
import { sortStringData } from 'century-core/core-utils/selectors/sortSelectors/sortSelectors';
import { DATE_FORMAT, padLeftWithZeroes } from 'century-core/core-utils/utils/utils';
import { SelectOption } from 'century-core/entities/SelectOption/SelectOption';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { AssignmentStatusTypes, PillVariantTypes } from '@ctek/design-system';
import {
  AssignmentClassGroup,
  AssignmentDetails,
  AssignmentGroup,
  AssignmentStatus,
  AssignmentStudentGroup,
} from 'century-core/core-apis/assignment/types';
import { extractDateAndTime } from 'century-core/core-utils/utils/date/date';
import { DAY_TO_SECONDS } from './createOrEditAssignment';
import { retrieveAssignableCourses } from 'century-core/core-apis/athena/courses/assignableCourses';

const DEFAULT_LATE_SUBMISSION = 'no' as const;
export type LateSubmissionOption = 'no' | '1' | '2' | '3' | '4' | '5' | '7' | '14' | '21' | '30';

export type AssignmentFormData = {
  assignmentName: string;
  subject: string;
  instructions: string;
  startDate: string;
  startTime: string;
  dueDate: string;
  dueTime: string;
  lateSubmission: LateSubmissionOption;
  multipleAttempts: boolean;
};

export type SortingDirection = 'asc' | 'desc';
export type GroupedDropdown = { key: string; title: string; entities: SelectOption[] };
export type AssignmentModalType = 'new' | 'edit' | '';

export const createGroupedDropdown = (key: string, entities: SelectOption[], title: string = ''): GroupedDropdown => ({
  key,
  title,
  entities: sortStringData(entities, 'title'),
});

export const getAllUniqueSubjects = async (
  token: string,
  assignedCourses: Ctek.Roentgen.CourseInfo[] | undefined,
  intl: IntlShape,
  signal?: AbortSignal
) => {
  const availableCourses = await retrieveAssignableCourses(token, signal);
  const subjectIds = availableCourses.data.courses.map(course => course.subject.id);
  const uniqueSubjectIds = Array.from(new Set(subjectIds));
  const subjects: Ctek.Label[] = await fetchLabelsByIds(uniqueSubjectIds, token, signal);

  const parsedSubjects = subjects.map(sub => ({ value: sub._id ?? '', title: sub.name, translation: sub.translationKey ?? '' }));

  const baseSubjects: SelectOption[] = uniqBy(
    assignedCourses?.map(({ subject: { labelId, name } }) => ({ value: labelId, title: name })),
    'value'
  );

  const classSubjects = intl.formatMessage({ id: 'class-subjects-dropdown-title', defaultMessage: 'Class Subjects' });
  const allSubjects = intl.formatMessage({ id: 'all-subjects-dropdown-title', defaultMessage: 'All Subjects' });

  if (!baseSubjects.length) {
    const filteredSubjects = parsedSubjects.filter(sub =>
      ['english', 'math', 'science'].some(keyword => sub.translation.includes(keyword))
    );
    if (filteredSubjects.length > 0) {
      return [createGroupedDropdown('group1', filteredSubjects), createGroupedDropdown('group2', parsedSubjects, allSubjects)];
    } else {
      return [createGroupedDropdown('group1', parsedSubjects)];
    }
  }

  return [createGroupedDropdown('group1', baseSubjects, classSubjects), createGroupedDropdown('group2', parsedSubjects, allSubjects)];
};

export const getDateRanges = (startDate: string, allowOpenDueDate = false) => {
  const today = DateTime.local().startOf('day');
  // const sevenDaysAgo = today.minus({ days: 7 }).toFormat(DATE_FORMAT); // TBD: Uncomment when needed
  const fromToday = today.toFormat(DATE_FORMAT);
  const sixtyDaysFromNow = today.plus({ days: 60 }).toFormat(DATE_FORMAT);
  const oneYearFromNow = today.plus({ years: 1 }).toFormat(DATE_FORMAT);

  const startDateObj = startDate ? DateTime.fromISO(startDate) : null;
  const minDueDate = startDateObj && startDateObj >= today ? startDate : fromToday;

  const sixtyDaysFromStartDate = startDate ? DateTime.fromISO(startDate).plus({ days: 60 }).toFormat(DATE_FORMAT) : sixtyDaysFromNow;
  const maxDueDate = allowOpenDueDate ? oneYearFromNow : sixtyDaysFromStartDate;

  const startDateRange = [fromToday, oneYearFromNow];
  const dueDateRange = [minDueDate, maxDueDate];

  return { startDateRange, dueDateRange };
};

export const LATE_SUBMISSION_OPTIONS = [
  { value: 'no', messageId: 'late-submission-no', defaultMessage: 'No' },
  { value: '1', messageId: 'late-submission-one-day', defaultMessage: '1 day' },
  { value: '2', messageId: 'late-submission-two-days', defaultMessage: '2 days' },
  { value: '3', messageId: 'late-submission-three-days', defaultMessage: '3 days' },
  { value: '4', messageId: 'late-submission-four-days', defaultMessage: '4 days' },
  { value: '5', messageId: 'late-submission-five-days', defaultMessage: '5 days' },
  { value: '7', messageId: 'late-submission-one-week', defaultMessage: '1 week' },
  { value: '14', messageId: 'late-submission-two-weeks', defaultMessage: '2 weeks' },
  { value: '21', messageId: 'late-submission-three-weeks', defaultMessage: '3 weeks' },
  { value: '30', messageId: 'late-submission-one-month', defaultMessage: '1 month' },
] as const;

export const translateLateSubmission = (value = '') =>
  LATE_SUBMISSION_OPTIONS.find(option => option.value === value) || LATE_SUBMISSION_OPTIONS[0];

/**
 * This function gets the current time rounded to the next interval defined by the deltaSize.
 *
 * @Examples:
 * 60-30 min
 * • 15:01 – 15:30 goes to 16:00
 * • 15:31 – 16:00 goes to 16:30
 *
 * 30-15 min
 * current time  | return time
 * 15:01 – 15:15 | 15:30
 * 15:16 – 15:30 | 15:45
 * 15:31 – 15:45 | 16:00
 * 15:46 – 16:00 | 16:15
 */
export const getCurrentHour = (date = new Date(), deltaSize = 30) => {
  const minutes = date.getMinutes();
  const deltaCount = Math.floor(minutes / deltaSize);
  const deltaRemainder = minutes % deltaSize;
  const adjustedMinutes = (deltaCount + (deltaRemainder > 0 ? 2 : 1)) * deltaSize;
  const adjustedTime = DateTime.fromJSDate(date).minus({ minute: minutes }).plus({ minute: adjustedMinutes });

  return `${padLeftWithZeroes(adjustedTime.hour, 2)}:${padLeftWithZeroes(adjustedTime.minute, 2)}`;
};

export const statusToVariantMap = {
  DRAFT: PillVariantTypes.WARNING,
  SCHEDULED: PillVariantTypes.DEFAULT,
  IN_PROGRESS: PillVariantTypes.ACTIVE,
  ENDED: PillVariantTypes.ACTIVE,
  CLOSED: PillVariantTypes.INFO,
  ARCHIVED: PillVariantTypes.DEFAULT,
  NOT_PUBLISHED: PillVariantTypes.DEFAULT,
} as const;

// TODO: Until we have custom schedules, this is the same logic for all widgets
export const canModifyItem = (status: AssignmentStatus): boolean => {
  switch (status) {
    case 'DRAFT':
    case 'NOT_PUBLISHED':
    case 'SCHEDULED':
    case 'IN_PROGRESS':
      return true;
    default:
      return false;
  }
};

export const createInitialSettings = (subject: string): AssignmentFormData => ({
  assignmentName: '',
  subject,
  instructions: '',
  startDate: '',
  startTime: '08:00',
  dueDate: '',
  dueTime: '22:00',
  lateSubmission: '7',
  multipleAttempts: true,
});

export const extractAssignmentFormData = (assignment: AssignmentDetails): AssignmentFormData => {
  const { date: startDate, time: startTime } = extractDateAndTime(assignment.schedule?.startAt);
  const { date: dueDate, time: dueTime } = extractDateAndTime(assignment.schedule?.dueAt);
  const lateSubmissionInDays = getLateSubmissionInDays(assignment);

  return {
    assignmentName: assignment.name,
    subject: assignment.subjectId,
    instructions: assignment.instructions,
    startDate,
    startTime: startTime || '08:00',
    dueDate,
    dueTime: dueTime || '22:00',
    lateSubmission: lateSubmissionInDays,
    multipleAttempts: assignment.options.reattemptsAllowed,
  };
};

export const resetScheduleDatesIfPastStartDate = (assignment: AssignmentDetails) => {
  const { startAt, dueAt, closeAt, ...scheduleOptions } = assignment.schedule!;

  if (assignment.schedule?.startAt && DateTime.fromISO(assignment.schedule?.startAt) <= DateTime.local()) {
    return { schedule: scheduleOptions };
  }

  return undefined;
};

export const modifiedFormFields = <T extends {}>(a: T, b: T) => {
  return (
    Object.keys(a)
      .filter(key => b[key] !== a[key])
      .join(', ') || 'none'
  );
};

export function isClassAssignmentGroup(group: AssignmentGroup): group is AssignmentClassGroup {
  return group.kind === 'Class';
}

export function isStudentAssignmentGroup(group: AssignmentGroup): group is AssignmentStudentGroup {
  return group.kind === 'Student';
}

export const getAssignmentStatusDot = (status: AssignmentStatus) => {
  if (status === 'ENDED' || status === 'IN_PROGRESS') return AssignmentStatusTypes.IN_PROGRESS;
  return AssignmentStatusTypes[status];
};

export const getLateSubmissionInDays = (assignment: AssignmentDetails) => {
  if (!assignment?.schedule?.closeDurationSeconds) return DEFAULT_LATE_SUBMISSION;
  return Math.floor(assignment?.schedule.closeDurationSeconds / DAY_TO_SECONDS).toString() as LateSubmissionOption;
};
export const filterClassAssignmentGroup = (groups: AssignmentGroup[]) => groups.filter(isClassAssignmentGroup);
export const filterStudentAssignmentGroup = (groups: AssignmentGroup[]) => groups.filter(isStudentAssignmentGroup);
