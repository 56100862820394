import * as React from 'react';
import { useAccessTokenRef, useAuth } from 'century-core/core-auth/hooks/useAuth';
import { ProductKey, PurchasedProduct, UserCurrentSubscription, UserSubscriptionHistory, SubscriptionType } from '../types';
import { fetchUserSubscription } from '../api/fetchUserSubscription';
import { useB2CVersion2 } from 'century-core/core-utils/hooks/useB2C';
import { MixpanelKeys, MixpanelValues } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { DateTime } from 'luxon';
import { PRODUCT_KEYS, isCurrentUserAndDuringTrial } from 'century-core/core-subscription/utils';
import { Helmet } from 'react-helmet';
import { useUser } from 'century-core/core-auth/components/UserProfile/UserProfileContext';
import { useLearnerProductContext } from './LearnerProductContext';
import { getUserName } from 'century-core/core-utils/selectors/users';
import {
  getFeatureFlagData,
  getTokenMainOrgId,
  getTokenMainOrgName,
  getTokenMainOrgType,
  hasGuardianRole,
  isLoggedIn,
} from 'century-core/core-auth/utils';
import { LoadingSpinner } from '@ctek/design-system';
import { useB2CMixpanelProperties } from 'century-core/core-subscription/hooks/use-b2c-mixpanel-properties';
import mixpanel from 'mixpanel-browser';
import { usePostHog } from 'posthog-js/react';

interface SubscriptionContextType {
  userSubscription: UserCurrentSubscription | UserSubscriptionHistory | undefined;
  subscriptionErrorMessage: string;
  fetchCurrentSubscription: () => Promise<void>;
  isSubscriptionLoading: boolean;
  subscriptionTrackingDetails: { [k in MixpanelKeys]?: MixpanelValues } | null;
  subscriptionType: SubscriptionType;
  setSubscriptionType: (subscriptionType: SubscriptionType) => void;
}

interface SubscriptionContextProviderProps {
  children: React.ReactNode;
}
const SubscriptionContext = React.createContext<SubscriptionContextType>({} as SubscriptionContextType);

export const useSubscriptionContext = () => React.useContext(SubscriptionContext);

export const useCurrentSubscription = () => useSubscriptionContext().userSubscription as UserCurrentSubscription;

export const useSubscriptionType = () => useSubscriptionContext().subscriptionType;

const sortProductsByProductKeyAndAlphabetical = (productA: PurchasedProduct, productB: PurchasedProduct) => {
  const planOrder = [ProductKey.CENTURY, ProductKey.Bond, ProductKey.BondPro];
  const indexA = planOrder.findIndex(p => p === productA.subscriptionItem.productKey);
  const indexB = planOrder.findIndex(p => p === productB.subscriptionItem.productKey);
  if (indexA < indexB) {
    return -1;
  }
  if (indexB < indexA) {
    return 1;
  }
  if (productA.learner && !productB.learner) {
    return -1;
  }
  if (productB.learner && !productA.learner) {
    return 1;
  }
  if (!productA.learner && !productB.learner) {
    return 0;
  }
  const nameA = `${productA.learner!.firstName} ${productA.learner!.lastName}`.toLowerCase();
  const nameB = `${productB.learner!.firstName} ${productB.learner!.lastName}`.toLowerCase();

  return nameA < nameB ? -1 : nameB > nameA ? 1 : 0;
};

export const useCurrentAssignedProducts = () => {
  const subscription = useCurrentSubscription();
  return React.useMemo(
    () =>
      (subscription?.products?.filter(p => p.learner) || []).sort(sortProductsByProductKeyAndAlphabetical) as (PurchasedProduct & {
        learner: Required<PurchasedProduct>;
      })[],
    [subscription]
  );
};

export const useCurrentAssignedProductKeys = () => {
  const products = useCurrentAssignedProducts();
  return React.useMemo(() => Array.from(new Set(products.map(product => product.subscriptionItem.productKey))), [products]);
};

export const useLearnerAssignedProduct = (userId: string) => {
  const subscription = useCurrentSubscription();
  return React.useMemo(() => subscription?.products?.find(p => p.learner?.userId === userId), [subscription?.products, userId]);
};

export const useLearnerIsBond = (userId: string) => {
  const learnerAssignedProduct = useLearnerAssignedProduct(userId);
  return ['bond-premium', 'bond-premium-pro'].includes(learnerAssignedProduct?.subscriptionItem.productKey || '');
};

type AppcuesIdentifierData = { [k: string]: string | number | boolean | undefined };

const useAppcuesIdentify = () => {
  const learnerProductContext = useLearnerProductContext();
  const currentSubscription = useSubscriptionContext().userSubscription as UserCurrentSubscription;

  const user = useUser();
  const auth = useAuth();
  const learnerId = learnerProductContext?.product?.learner?.userId;
  const appcuesIdentifiers: AppcuesIdentifierData = {
    name: user ? getUserName(user) : '',
    email: user?.contact?.emails?.[0]?.address,
    role: hasGuardianRole(auth) ? 'guardian' : 'learner',
  };
  if (learnerProductContext.product?.learner) {
    appcuesIdentifiers.productKey = learnerProductContext.product.productKey;
    appcuesIdentifiers.role = 'learner';
  } else if (currentSubscription && typeof currentSubscription.active === 'boolean') {
    appcuesIdentifiers.role = 'guardian';
    appcuesIdentifiers.active = currentSubscription.active;
  }
  const userId = hasGuardianRole(auth) ? user?._id : learnerId;

  const appcuesUserRef = React.useRef('');
  const appcuesIdentify = (window as any).Appcues?.identify;
  if (userId && appcuesUserRef.current !== userId && !!appcuesIdentify) {
    appcuesIdentify(userId, appcuesIdentifiers);
    appcuesUserRef.current = userId;
  }
};

export const useFetchSubscriptionOnPageView = () => {
  const { fetchCurrentSubscription } = useSubscriptionContext();
  React.useEffect(() => {
    const visibilityChangeUpdate = () => {
      if (document.visibilityState === 'visible') {
        fetchCurrentSubscription?.();
      }
    };
    document.addEventListener('visibilitychange', visibilityChangeUpdate);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeUpdate);
    };
  }, [fetchCurrentSubscription]);
};

export const SubscriptionContextProvider = (props: SubscriptionContextProviderProps) => {
  const isB2CVersion2 = useB2CVersion2();
  const posthog = usePostHog();
  const accessTokenRef = useAccessTokenRef();
  const b2cProperties = useB2CMixpanelProperties();
  const auth = useAuth();
  const isGuardianRef = React.useRef(hasGuardianRole(auth));
  const appcuesId = React.useMemo(() => getFeatureFlagData<string>(auth, 'b2c', 'appcuesId'), [auth]);
  const [userSubscription, setUserSubscription] = React.useState<UserCurrentSubscription | UserSubscriptionHistory | undefined>();
  // TODO error message for core subscription data needs to be decoupled from subscription management data
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] = React.useState('');
  const [isSubscriptionLoading, setIsSubscriptionLoading] = React.useState(false);
  const [subscriptionType, setSubscriptionType] = React.useState(SubscriptionType.None);

  React.useEffect(() => {
    isGuardianRef.current = hasGuardianRole(auth);
  }, [auth]);

  const fetchCurrentSubscription = React.useCallback(async () => {
    if (isB2CVersion2) {
      setSubscriptionErrorMessage('');
      setIsSubscriptionLoading(true);
      try {
        const userSubscription = await fetchUserSubscription(accessTokenRef.current);
        setUserSubscription(userSubscription);
      } catch (error) {
        setSubscriptionErrorMessage(error.message);
      }
      setIsSubscriptionLoading(false);
    }
  }, [accessTokenRef, isB2CVersion2]);

  // fire on page load and when the logged in user changes©
  React.useEffect(() => {
    if (isLoggedIn(auth)) {
      if (isGuardianRef.current) {
        fetchCurrentSubscription();
      }

      if (auth.accessToken && userSubscription) {
        posthog?.group('B2C Organisation', getTokenMainOrgId(auth) || 'N/A', {
          name: getTokenMainOrgName(auth) || 'N/A',
          [MixpanelKeys.OrganisationType]: getTokenMainOrgType(auth) || 'N/A',
          ...b2cProperties,
        });

        mixpanel.register({
          ...b2cProperties,
        });
      }

      isGuardianRef.current = false;
    }
  }, [auth, accessTokenRef, fetchCurrentSubscription, userSubscription, b2cProperties, posthog]);

  const subscriptionTrackingDetails = React.useMemo(() => {
    if ((userSubscription as UserSubscriptionHistory)?.subscription?.active) {
      return {
        [MixpanelKeys.SubscriptionCtxLegacyUser]: true,
        [MixpanelKeys.SubscriptionCtxIsActive]: true,
      };
    }

    const currentSubscription = userSubscription as UserCurrentSubscription;
    if (!currentSubscription || !currentSubscription.active) {
      return {
        [MixpanelKeys.SubscriptionCtxIsActive]: false,
      };
    }

    const isCurrentlyOnTrial = isCurrentUserAndDuringTrial(currentSubscription);
    const data: { [k in MixpanelKeys]?: string | number | boolean | any[] } = {
      [MixpanelKeys.SubscriptionCtxStripeCustomerId]: currentSubscription.id,
      [MixpanelKeys.SubscriptionId]: currentSubscription.subscriptionId,
      [MixpanelKeys.SubscriptionCtxTotalPlans]: currentSubscription.products?.length,
      [MixpanelKeys.SubscriptionCtxIsActive]: true,
    };
    if (typeof currentSubscription.subscriptionTotal === 'number') {
      data[MixpanelKeys.SubscriptionCtxPrice] = currentSubscription.subscriptionTotal;
    }
    if (isCurrentlyOnTrial && currentSubscription.trialEndDate) {
      const trialEndDate = DateTime.fromSeconds(currentSubscription.trialEndDate);
      if (trialEndDate > DateTime.local()) {
        data[MixpanelKeys.SubscriptionCtxTrialEnd] = trialEndDate.toISO();
        data[MixpanelKeys.IsTrial] = true;
      }
    }
    if (currentSubscription.cancelOn) {
      data[MixpanelKeys.SubscriptionCtxCancellationDate] = DateTime.fromSeconds(currentSubscription.cancelOn).toISO();
    }
    if (!!currentSubscription.latestPaymentFailed) {
      data[MixpanelKeys.SubscriptionCtxPastDue] = true;
    }
    data[MixpanelKeys.SubscriptionCtxDependants] = currentSubscription.products.map(p => p.learner?.userId).filter(d => !!d);
    const productsGrouped = currentSubscription.products.reduce((acc, product) => {
      if (!acc[product.subscriptionItem.productKey]) {
        acc[product.subscriptionItem.productKey] = [];
      }
      acc[product.subscriptionItem.productKey].push(product);
      return acc;
    }, {} as { [k in ProductKey]: UserCurrentSubscription['products'] });

    // dunno why but typescript insisted on this being declared separately
    PRODUCT_KEYS.forEach((productKey: ProductKey) => {
      const totalProduct = productsGrouped[productKey]?.length || 0;
      const learnerCount = productsGrouped[productKey]?.filter(p => p.learner)?.length || 0;
      const unassignedCount = totalProduct - learnerCount;
      if (totalProduct) {
        data[`Subscription: ${productKey} Quantity`] = totalProduct;
      }
      if (unassignedCount !== 0) {
        data[`Subscription: ${productKey} Unassigned`] = unassignedCount;
      }
    });

    return data;
  }, [userSubscription]);

  useAppcuesIdentify();

  const helmetContent = React.useMemo(
    () =>
      appcuesId && (
        <Helmet>
          <script type="text/javascript" id="hello">
            {`window.AppcuesSettings = {
            enableURLDetection: true
          };`}
          </script>
          <script src={`//fast.appcues.com/${appcuesId}.js`} />
        </Helmet>
      ),
    [appcuesId]
  );

  React.useEffect(() => {
    if (userSubscription && !!(userSubscription as UserCurrentSubscription).active) {
      setSubscriptionType((userSubscription as UserCurrentSubscription)!.recurringInterval);
    }
  }, [userSubscription]);

  if (isSubscriptionLoading && !userSubscription) {
    return <LoadingSpinner type="fullpage" />;
  }

  return (
    <SubscriptionContext.Provider
      value={{
        userSubscription,
        subscriptionErrorMessage,
        fetchCurrentSubscription,
        isSubscriptionLoading,
        subscriptionTrackingDetails,
        subscriptionType,
        setSubscriptionType,
      }}
    >
      {helmetContent}
      {props.children}
    </SubscriptionContext.Provider>
  );
};
