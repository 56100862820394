import { Widget } from '@ctek/design-system';
import WelcomeWidgetWelcomeMessage from './WelcomeWidgetWelcomeMessage';
import WelcomeWidgetMessagesMode from './WelcomeWidgetMessagesMode';
import { MessageMode } from './utils';

const WelcomeWidget = ({ firstName }: { firstName: string }) => {
  return (
    <Widget qa="widget--welcome-message">
      <Widget.Body qa="widget--welcome-message--body">
        <WelcomeWidgetWelcomeMessage firstName={firstName} />
        <WelcomeWidgetMessagesMode messageMode={MessageMode.COGNITIVE} />
      </Widget.Body>
    </Widget>
  );
};

export default WelcomeWidget;
