import _get from 'lodash/get';
import _flatten from 'lodash/flatten';
import { timeSpanControls, TimeSpanKey } from 'century-core/features-dashboard/TeacherDashboard/common/utils/timeSpanUtils';
import { DateTime } from 'luxon';
import {
  CompletionNugget,
  CompletionStudent,
  DateRange,
  getStudentsNuggetData,
  NuggetHash,
  Student,
  TimeBasedNuggetActivity,
  CompletionNuggetDetails,
} from 'century-core/core-apis/learn/aggregations/aggregations';

export function getCompletionNuggets(nuggets: Ctek.Nugget[]): any[] {
  return nuggets.map((nugget: Ctek.Nugget) => ({
    _id: nugget._id,
    name: nugget.name,
    students: {},
    isActive: false,
  }));
}
export async function getNuggetCompletionData(
  accessToken: string,
  users: Ctek.User[],
  strands: Ctek.Strand[],
  selectedStrands: string[],
  dateRange?: DateRange,
  timeSpan?: TimeSpanKey
): Promise<CompletionNugget[]> {
  const selectedDateRange =
    timeSpan === TimeSpanKey.CustomRange || timeSpan === TimeSpanKey.CurrentAcademicYear
      ? dateRange
      : {
          startDate: timeSpanControls[timeSpan || TimeSpanKey.AllTime].getValue?.() || undefined,
          endDate: DateTime.local().toISO(),
        };
  let completedNuggets: CompletionNugget[] = [];

  let selectedNuggets: any[] = [];
  selectedStrands.forEach((selectedStrand: string) => {
    const thisStrand = strands?.find((strand: Ctek.Strand) => strand.id === selectedStrand);
    const nuggs = thisStrand?.nuggets.map((nugget: Ctek.Nugget) => nugget);
    if (!!nuggs?.length) {
      selectedNuggets = [...selectedNuggets, nuggs];
    }
  });
  selectedNuggets = selectedNuggets.flat();
  completedNuggets = getCompletionNuggets(selectedNuggets);
  const students: any[] = users.map(user => {
    return {
      _id: user._id,
      name: `${user.personal?.name?.first} ${user.personal?.name?.last}`,
    };
  });
  return getStudentsNuggetData(accessToken, completedNuggets, students, selectedDateRange)
    .then<TimeBasedNuggetActivity[]>(_flatten as any)
    .then((allData: TimeBasedNuggetActivity[]) => {
      return mixInNuggetResults(students, completedNuggets, getNuggetHash(allData));
    });
}

export function mixInNuggetResults(students: Student[], uniqueNuggets: CompletionNugget[], nuggetHash: NuggetHash): CompletionNugget[] {
  const enrichedNuggets: any[] = [];
  uniqueNuggets.forEach((nugget: CompletionNugget) => {
    const enrichedStudents: any = {};
    let isActive: boolean = false;
    students.forEach((student: CompletionStudent) => {
      const lastResultScore = _get(nuggetHash, [`${student._id}`, `${nugget._id}`, 'lastResult', 'score'], null) as number;
      isActive = isActive || typeof lastResultScore === 'number';
      enrichedStudents[student._id as string] = {
        bestResult: _get(nuggetHash, [`${student._id}`, `${nugget._id}`, 'bestResult']),
        averageResult: _get(nuggetHash, [`${student._id}`, `${nugget._id}`, 'averageResult']),
        lastResult: {
          score: lastResultScore,
          date: _get(nuggetHash, [`${student._id}`, `${nugget._id}`, 'lastResult', 'date'], null),
        },
      };
    });

    enrichedNuggets.push({
      ...nugget,
      students: enrichedStudents,
      isActive,
    });
  });
  return enrichedNuggets;
}

export function getNuggetHash(nuggetResults: TimeBasedNuggetActivity[]): NuggetHash {
  const returnObject: NuggetHash = {};

  const uniqUserIds = Array.from(new Set(nuggetResults.map(nuggetResult => nuggetResult.userId)));
  uniqUserIds.forEach((id: string) => {
    const userNuggetResults = nuggetResults.filter(nuggetResult => nuggetResult.userId === id);

    const mergedResults = userNuggetResults.reduce((acc, currResult) => {
      if (currResult.attemptsClosed > 0) {
        acc[currResult.nuggetId] = {
          id: currResult.nuggetId,
          lastResult: {
            score: currResult.percentScore.closed.latest === -1 ? -1 : Math.round(currResult.percentScore.closed.latest / 100),
            date: currResult.latestActivityTimeClosed,
          },
          bestResult: currResult.percentScore.closed.max === -1 ? -1 : Math.round(currResult.percentScore.closed.max / 100),
          averageResult: currResult.percentScore.closed.average === -1 ? -1 : Math.round(currResult.percentScore.closed.average / 100),
          hasNoQuestions: currResult.percentScore.all.max === -1,
        };
      }
      return acc;
    }, {} as { [key: string]: CompletionNuggetDetails });

    returnObject[id] = mergedResults;
  });

  return returnObject;
}
