import { DateTime } from 'luxon';
import _get from 'lodash/get';
import _truncate from 'lodash/truncate';

export const MIN_PASSWORD_LENGTH = 8;
export const MIN_DOB = '1900-01-01';
export const DATE_FORMAT = 'yyyy-LL-dd';

export const focusInputElement = (element: HTMLInputElement) => {
  if (element) {
    element.focus();
  }
};

export function getLocationUrlParam(param: string): string {
  const params = new URL(decodeURIComponent(document.location.href)).searchParams;
  return params.get(param) || '';
}

export function isValidHttpUrl(urlString: string): boolean {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function removeItemFromCollection<T>(idToRemove: string, idFieldName: string, collection: T[]): T[] {
  const elemIndex = collection.findIndex(item => item[idFieldName] === idToRemove);
  collection.splice(elemIndex, 1);
  return collection;
}

export function compareArrays<T>(array1: T[], array2: T[]): boolean {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export const filter = (cb: any, arr: { filter: (arg0: any) => void }): any => arr.filter(cb);

export const map = (cb: any, arr: { map: (arg0: any) => void }) => arr.map(cb);

export const getDataFromStringPath = (name: string, dataObject: any, defaultValue: any = null) => {
  if (!name.match(/\[|\]|\./)) {
    return dataObject[name];
  }
  const keyChain = name.split(/\[['"]?|['"]?\]|\./g).filter(v => v);
  return _get(dataObject, keyChain, defaultValue);
};

export const padLeftWithZeroes = (input: string | number, length: number): string => {
  return String(input).padStart(length, '0');
};
export function orderUsersAlphabeticallyByLastName(users: Ctek.User[]): Ctek.User[] {
  return users.sort((a: Ctek.User, b: Ctek.User) => {
    const nameA = a?.personal?.name?.last.toLowerCase() || '';
    const nameB = b?.personal?.name?.last.toLowerCase() || '';
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
}
export function getDateRange(option: string) {
  switch (option) {
    case 'AllTime':
      return { startDate: '', endDate: '' };
    case 'OneDay':
      return { startDate: getEndDateFormatted(0), endDate: getTodayFormatted() };
    case 'SevenDays':
      return { startDate: getEndDateFormatted(7), endDate: getTodayFormatted() };
    case 'TwentyEightDays':
      return { startDate: getEndDateFormatted(28), endDate: getTodayFormatted() };
    case 'AcademicYear':
      return { startDate: getAcademicYearStartDate(), endDate: getTodayFormatted() };
    default:
      return { startDate: '', endDate: '' };
  }
}

export function getTodayFormatted(): string {
  return DateTime.local().toFormat('yyyy-LL-dd') + 'T23:59:59.000Z';
}

export function getEndDateFormatted(dayCount: number): string {
  return DateTime.local().minus({ days: dayCount }).toFormat('yyyy-LL-dd') + 'T00:00:00.000Z';
}

export function getAcademicYearStartDate(): string {
  const start = DateTime.local();
  if (start.month < 8) {
    // need to get sept of previous year
    start.minus({ year: start.year - 1 }); // set the year back one
  }
  start.month = 8;
  start.day = 1;
  return start.toFormat('yyyy-LL-dd') + 'T00:00:00.000Z';
}

export const getRandomIntegerBetween = (min: number, max: number): number => Math.floor(Math.random() * max) + min;

export enum DashboardUserMode {
  TEACHER,
  GUARDIAN,
  STUDENT,
}

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const isFileAPISupported = (): boolean => {
  return !!(window.File && window.FileList && window.FileReader);
};

/**
 * Returns a cleaned version of the input string with all non-alphanumeric characters removed.
 * @param str The string to clean.
 * @returns The cleaned string.
 */
export const sanitizeString = (str: string) => str.toLowerCase().replace(/[^a-zA-Z0-9 _-]/g, '');

// This formats dob automatically. When user completes typing 4 digits, it adds a dash.
// When user completes typing 6 digits, it adds a dash.
export const formatDOB = (input: string): string => {
  const formattedInput = input.replace(/[^0-9]/g, '');
  const formattedArray = [formattedInput.slice(0, 4), formattedInput.slice(4, 6), formattedInput.slice(6, 8)];
  return formattedArray.filter(Boolean).join('-');
};

export const isValidPartialDate = (input: string): boolean => {
  if (input.length === 4) {
    const year = parseInt(input, 10);
    const currentYear = new Date().getFullYear();
    return year >= 1900 && year <= currentYear;
  } else if (input.length === 7) {
    const month = parseInt(input.slice(5), 10);
    return month >= 1 && month <= 12;
  }
  return true;
};

export const booleanOrUndefined = (value: string | number | boolean | undefined | null) => {
  switch (value) {
    case 'false':
    case false:
    case 0:
      return false;
    case undefined:
    case 'undefined':
    case 'null':
    case '':
      return undefined;
    case 'true':
    case true:
    default:
      return true;
  }
};

/**
 * Truncates a given text to a specified length.
 * If no length is provided, it defaults to 30.
 */
export const truncateText = (text?: string, length: number = 30) => _truncate(text, { length, omission: '...' });

export const getAppArea = (pathname: string): string => {
  const routeNames: { [key: string]: string } = {
    '/teach/classes': 'Teacher Dashboard',
    '/learn/my-journey': 'My Journey',
    '/learn/my-path': 'My Path',
    '/learn/my-courses': 'My Courses',
    '/learn/assignments/due': 'My Assignments',
    '/learn/my-dashboard/overview': 'My Dashboard',
    '/cms/courses': 'Courses',
    '/cms/schemes-of-work': 'My Planner',
    '/assignments': 'Assignments',
    '/assessments': 'Assessments',
    '/guardian/dependants': 'Guardian Dashboard',
    '/guardian/subscription': 'My Subscription',
    '/leadership': 'Leadership Dashboard',
    '/admin/classes': 'Class Admin',
    '/my-assessments': 'My Assessments',
  };

  const transformedPath = pathname.toLowerCase();
  const basePath = Object.keys(routeNames).find(route => transformedPath.startsWith(route));
  return basePath ? routeNames[basePath] : '';
};
