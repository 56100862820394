import { Auth } from 'century-core/entities/Auth/Auth';
import { getPrimaryOrgRoles, hasSpecificRoleOnMainOrg } from './roles';

/**
 * Default values for features that are not always set in the features list.
 */
export const FeatureFlagDefaultValues = {
  canContinueStudySession: true,
  canRateStudySession: true,
  canSelectMood: true,
  showFooter: true,
  showFooterMood: true,
  isRevealsEnabled: true,
  isDiagnosticModeEnabled: true,
  showBreadcrumbs: true,
  newAssignments: true,
};

/**
 * Default values for features that are not always listed to be overridden
 * By default, most of the overridden features are enabled.
 */
export const FeatureOverrideDefaultValues = {
  b2c: false,
  guardian: false,
};

/**
 * Validates whether a specific feature in a section is available
 * @param auth
 * @param sectionName
 * @param featureName
 * @param defaultValue optional, usually FALSE, but it's here in case the default is a different value (i.e. global.isRevealsEnabled)
 *
 * @returns boolean, whether ????
 */
export const hasFeature = (auth: Auth, sectionName: Ctek.Section, featureName: string, defaultValue: boolean = false): boolean => {
  const sectionData = getFeatureSectionData(auth, sectionName);
  if (!sectionData) {
    return defaultValue;
  }
  const featureData: Ctek.FeatureFlagValue | undefined = getFeatureFlagData(auth, sectionName, featureName);

  if (featureData && (featureData as Ctek.ToggleableFeature).isEnabled === false) {
    return false;
  }

  return featureData === undefined ? defaultValue : !!featureData;
};

/**
 * Returns a specific feature flag object/value from a specific section if its available.
 * Returns undefined if feature flag is not present in specific section
 *
 * Availability is computed based on roles and/or isEnabled field of the feature flag object
 *
 * Usage:
 *
 * const helpPage = getFeatureFlagData<{url: string}>(auth, 'customer-success', 'helpPage')?.url;
 *
 * const homeRedirect = getFeatureFlagData<{ name: string; url: string }>(auth, 'main-menu', 'homeRedirect');
 * if (homeRedirect) {
 *   const label = homeRedirect.name;
 *   const redirectUrl = homeRedirect.url;
 * }
 *
 * @param auth
 * @param sectionName
 * @param featureName
 */
export function getFeatureFlagData<T extends Ctek.FeatureFlagValue>(
  auth: Auth,
  sectionName: Ctek.Section,
  featureName: string
): T | undefined {
  const sectionData = getFeatureSectionData(auth, sectionName);

  if (!sectionData?.hasOwnProperty(featureName)) {
    return undefined;
  }
  const featureFlagValue = sectionData[featureName];

  if (featureFlagValue === undefined) {
    return undefined;
  }

  // Filter feature flags where the user does not have the required role for the feature flag to apply.
  if (isFeatureFlagEnabledForUsersRole(auth, featureFlagValue) === undefined) {
    return undefined;
  }

  return featureFlagValue as T;
}

/**
 * Checks if feature is enabled or disabled based on a users roles.
 *
 * If the feature flag has no roles specified, return whether it is enabled or not.
 * If the feature flag has roles defined - if the user does not have the roles return undefined as this feature flag does not apply to the user
 * If the feature flag has roles defined - if the user does have the roles return the value of isEnabled
 *
 * | User has required roles | Feature Enabled |  Result   |
 * _________________________________________________________
 * |        true             |    true         |   true    |
 * |        false            |    true         | undefined |
 * |        false            |    false        | undefined |
 * |        true             |    false        | false     |
 * |        undefined        |    true         | true      |
 * |        undefined        |    false        | false     |
 * |        undefined        |    undefined    | undefined |
 * |        any              |    undefined    | undefined |
 */
const isFeatureFlagEnabledForUsersRole = (auth: Auth, featureFlagValue: Ctek.FeatureFlagValue): boolean | undefined => {
  let roleTrigger = true;

  const isEnabledTrigger = (featureFlagValue as Ctek.ToggleableFeature).isEnabled;
  const roleCast = featureFlagValue as Ctek.ToggleableRoleBasedFeature;

  if (isEnabledTrigger === undefined) {
    return true;
  }

  if (roleCast.roles?.length > 0) {
    roleTrigger = roleCast.roles.some(role => hasSpecificRoleOnMainOrg(auth, role));
  }

  if (!roleTrigger) {
    return undefined;
  }

  return isEnabledTrigger;
};

/**
 * Gets feature data from a specific section.
 * Returns the section data
 * Returns undefined if section does not exits in features list
 */
const getFeatureSectionData = (auth: Auth, sectionName: Ctek.Section): Ctek.SectionData | undefined => {
  const sectionObj = auth.accessTokenData?.context?.settings?.features?.find(
    (feature: Ctek.JWTDataFeatureSection) => feature.section === sectionName
  );
  return sectionObj?.data;
};

/**
 * Checks if a specific feature section is listed in settings.feature list
 */
export const hasFeatureSection = (auth: Auth, sectionName: Ctek.Section): boolean => !!getFeatureSectionData(auth, sectionName);

/**
 * FEATURE-OVERRIDES
 */

export type featureName =
  | 'admin'
  | 'assessments'
  | 'assignments'
  | 'b2c'
  | 'cms'
  | 'courses'
  | 'dashboards'
  | 'external-home-redirect'
  | 'guardian'
  | 'main-menu'
  | 'my-path'
  | 'my-journey'
  | 'planner'
  | 'class-overview'
  | 'test-practice'
  | 'student-dashboard'
  | 'leadership-dashboard'
  | 'teacher-dashboard';

/**
 * Returns a feature override by name if it exists on the user
 */
export const getFeatureOverrideByName = (name: featureName, auth: Auth): Ctek.FeatureOverride | null => {
  const featureOverrides = getFeatureSectionData(auth, 'feature-overrides');

  if (!featureOverrides?.features) {
    return null;
  }
  const override = (featureOverrides?.features as Ctek.FeatureOverride[]).find((feature: Ctek.FeatureOverride) => {
    return feature ? feature.name === name : false;
  });

  return override || null;
};

/**
 * Checks if feature is enabled, value computed based on roles and/or isEnabled field of the feature override object
 * @param featureName
 * @param defaultValue true by default, currently only b2c/guardian are not enabled by default
 */
export const isFeatureOverrideEnabled = (auth: Auth, featureName: featureName, defaultValue: boolean = true): boolean => {
  const feature = getFeatureOverrideByName(featureName, auth);

  if (!feature) {
    return defaultValue;
  }

  const featureFlagEnabledForRoles = isFeatureFlagEnabledForUsersRole(auth, feature as Ctek.FeatureFlagValue);
  return featureFlagEnabledForRoles === undefined ? defaultValue : featureFlagEnabledForRoles;
};

/**
 * Although this function is based on FeatureOverride, it should only be used for features that are already enabled
 * by default in the app, not to activate features (i.e. b2c and related ones like guardian).
 */
export const shouldOverrideFeature = (name: featureName, auth: Auth): boolean => {
  const override = getFeatureOverrideByName(name, auth);
  if (!override) {
    return false;
  } else if (!override.roles) {
    return !override.isEnabled;
  } else {
    const authRoles = getPrimaryOrgRoles(auth);
    return override.roles.some(role => authRoles.indexOf(role) > -1);
  }
};

/**
 * Checks whether the auth has ANY of the FFs provided by param
 * @param auth
 * @param featureFlags an array of '{section: string, feature: string}' specifying the FFs to check
 * @returns
 */
export const checkHasAnyFeatureFlag = (auth: Auth, featureFlags: { section: Ctek.Section; feature: string }[]): boolean => {
  if (!featureFlags?.length) return false;
  return featureFlags?.some(featureFlag => getFeatureFlagData(auth, featureFlag.section, featureFlag.feature) !== undefined);
};
