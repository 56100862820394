import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { Dependant } from 'century-core/entities/User/User';
import * as SortSelectors from 'century-core/core-utils/selectors/sortSelectors/sortSelectors';
import * as stateSelectors from './stateSelectors';
import { getUserProfile } from 'century-core/core-utils/selectors/users';

export const getDependantList = createSelector([stateSelectors.getDependants, stateSelectors.getUserEntities], (userIds, entities) => {
  if (!entities || !userIds) {
    return null;
  }
  const dependants = (userIds as string[]).map(userId => getUserProfile(getEntity(userId, entities))).filter(user => !!user);
  return SortSelectors.sortStringData(dependants, 'name');
});

export const getInactiveDependants = createSelector(
  [stateSelectors.getDependants, stateSelectors.getUserEntities],
  (userIds: string[], entities) => getDependantsByActiveState(userIds, entities, false)
);

export const getActiveDependants = createSelector(
  [stateSelectors.getDependants, stateSelectors.getUserEntities],
  (userIds: string[], entities) => getDependantsByActiveState(userIds, entities, true)
);

function getDependantsByActiveState(
  userIds?: string[],
  entities?: Record<string, Ctek.User>,
  activeState: boolean = true
): null | Dependant[] {
  if (!entities || !userIds) {
    return null;
  }
  const dependants = userIds
    .map(userId => getEntity(userId, entities))
    .filter((u): u is Ctek.User => !!u && u.isEnabled === activeState)
    .map(u => getUserProfile(u) as Dependant);
  return SortSelectors.sortStringData(dependants, 'name');
}

export function getEntity<T>(id?: string, entities?: Record<string, T>): T | null {
  if (!entities || !id || !entities[id as string]) {
    return null;
  }
  return entities[id];
}

export const formatUserToUserProfile = (user: Ctek.User) => ({
  avatar: user.profile?.avatar || '',
  birthDate: user.personal?.birthDate,
  emails: user.contact?.emails || [],
  firstName: user.personal?.name?.first || '',
  lastName: user.personal?.name?.last,
  name: `${user.personal?.name?.first} ${user.personal?.name?.last}`,
  userId: user._id as string,
  username: user.profile?.ids?.username || '',
});

export const getLoggedinUserProfile = createSelector([stateSelectors.getAuth, stateSelectors.getUserEntities], (auth, entities) => {
  if (!entities || !auth || !auth.accessTokenData || !auth.accessTokenData.sub || !entities[auth.accessTokenData.sub]) {
    return null;
  }
  const user = entities[auth.accessTokenData.sub];
  return formatUserToUserProfile(user);
});

export const getCurrentUser = createSelector([stateSelectors.getAuth, stateSelectors.getUserEntities], (auth, entities) => {
  if (!entities || !auth || !auth.accessTokenData || !auth.accessTokenData.sub || !entities[auth.accessTokenData.sub]) {
    return null;
  }
  return entities[auth.accessTokenData.sub];
});

export const getLoggedInUsername = createSelector([stateSelectors.getAuth, stateSelectors.getUserEntities], (auth, users) => {
  return !auth || !auth.accessTokenData || !auth.accessTokenData.sub || !users || !users[auth.accessTokenData.sub]
    ? null
    : users[auth.accessTokenData.sub].profile?.ids?.username;
});

export const getLoggedInEmails = createSelector([stateSelectors.getAuth, stateSelectors.getUserEntities], (auth, users) => {
  const userId: null | string = _get(auth, ['accessTokenData', 'sub'], null);
  const emails: null | { address: string; isVerified: false }[] = userId && _get(users, [userId, 'contact', 'emails'], null);
  return emails || [];
});
