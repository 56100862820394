import { DateTime } from 'luxon';
import _get from 'lodash/get';
import { CustomGetter } from 'century-core/core-utils/utils/tableSorting/tableSorting';

export function sortIntegerData(
  data: any[],
  colName: string,
  isAscending: boolean = true,
  propName: string = '',
  customGetter?: CustomGetter | undefined
) {
  return [...data].sort((a: any, b: any) => {
    let propA;
    let propB;
    if (customGetter) {
      propA = customGetter(a, colName, propName);
      propB = customGetter(b, colName, propName);
    } else {
      propA = a[colName];
      propB = b[colName];
    }
    if (propA === propB) {
      return 0;
    } else if (propA === null) {
      return 1;
    } else if (propB === null) {
      return -1;
    } else if (isAscending) {
      return propA < propB ? -1 : propA > propB ? 1 : 0;
    } else {
      return propB < propA ? -1 : propB > propA ? 1 : 0;
    }
  });
}

export function sortStringData(
  data: any[],
  colName: string,
  isAscending: boolean = true,
  propName: string = '',
  customGetter?: CustomGetter | undefined,
  secondaryColName?: string
) {
  return [...data].sort((a: any, b: any) => {
    let propA;
    let propB;
    if (customGetter) {
      propA = customGetter(a, colName, propName);
      propB = customGetter(b, colName, propName);
    } else {
      propA = _get(a, colName.split('.'), '')?.toString().toUpperCase().trim() || '';
      propB = _get(b, colName.split('.'), '')?.toString().toUpperCase().trim() || '';
    }
    if (propA === '') {
      return 1;
    }

    if (propB === '') {
      return -1;
    }

    if (propA === propB && !!secondaryColName) {
      const propC = _get(a, secondaryColName.split('.'), '').toString().toUpperCase().trim();
      const propD = _get(b, secondaryColName.split('.'), '').toString().toUpperCase().trim();

      return (isAscending ? 1 : -1) * propC.localeCompare(propD);
    }

    return (isAscending ? 1 : -1) * propA.localeCompare(propB);
  });
}

export function sortBooleanData(
  data: any[],
  colName: string,
  isAscending: boolean = true,
  propName: string = '',
  customGetter?: CustomGetter | undefined
) {
  return [...data].sort((a: any, b: any) => {
    let propA;
    let propB;
    if (customGetter) {
      propA = customGetter(a, colName, propName);
      propB = customGetter(b, colName, propName);
    } else {
      propA = _get(a, colName.split('.'), '');
      propB = _get(b, colName.split('.'), '');
    }
    if (propA === null) {
      return 1;
    }

    if (propB === null) {
      return -1;
    }
    if (propA === propB) {
      return 0;
    }

    const x = isAscending ? 1 : -1;
    return propA ? x * -1 : x * 1;
  });
}

export function sortDateData(
  data: any[],
  colName: string,
  isAscending: boolean = true,
  propName: string = '',
  customGetter?: CustomGetter | undefined
) {
  return [...data].sort((a: any, b: any) => {
    let propA;
    let propB;
    if (customGetter) {
      propA = customGetter(a, colName, propName);
      propB = customGetter(b, colName, propName);
    } else {
      propA = a[colName];
      propB = b[colName];
    }
    if (propA === null) {
      return 1;
    } else if (propB === null) {
      return -1;
    } else {
      const dateA = DateTime.fromJSDate(new Date(propA)).toUTC();
      const dateB = DateTime.fromJSDate(new Date(propB)).toUTC();
      if (isAscending) {
        return dateA.diff(dateB, 'seconds').seconds;
      } else {
        return dateB.diff(dateA, 'seconds').seconds;
      }
    }
  });
}
